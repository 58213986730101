import { Box, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Property } from "src/api/sure-api/ssq/useGetOneSsq.ts";
import { Text } from "src/components/ScoreDetail/FieldTypes/Text.tsx";
import { Group } from "src/components/ScoreDetail/FieldTypes/Group.tsx";
import { MultivalueGroup } from "src/components/ScoreDetail/FieldTypes/MultivalueGroup.tsx";
import { Answer, createDataList } from "src/components/ScoreDetail/Responses/ResponsesModal.tsx";
import { ScoreDimensionValue } from "src/api/client-api/post/scoreSchema.ts";

const WrapperStyled = styled(Box)({
    width: "100%",
    "&:not(:last-of-type)": {
        borderBottom: "1px dashed #e3e2e0",
        paddingBottom: "16px",
        marginBottom: "16px",
    },
});

type ScoreDetailResponsesModalContentProps = {
    header?: string;
    elements: Answer[];
    ssqProperty: Property;
    score?: ScoreDimensionValue;
};
export const ResponsesModalContent = (props: ScoreDetailResponsesModalContentProps) => {
    const { header, elements, score, ssqProperty } = props;

    const { t } = useTranslation();

    const dataLists = ssqProperty !== undefined ? createDataList([ssqProperty]) : undefined;

    const getValue = (element: Answer): string => {
        const map = dataLists?.[element.ssqPropertyId];

        if (map instanceof Map) {
            return map.has(element.value) ? map.get(element.value)! : t("questionnaire.no_answer");
        }

        return element.value;
    };

    return (
        <WrapperStyled>
            {header && (
                <Box mb={2} width="100%">
                    <Typography variant="h4" component="h4">
                        {header}
                    </Typography>
                </Box>
            )}
            {elements.length === 0 ?
                <Typography variant="body" component="p">
                    {t("questionnaire.no_answer")}
                </Typography>
            :   <>
                    {["text", "select", "radio", undefined].includes(ssqProperty.field?.type) &&
                        (() => {
                            return elements.length > 1 ?
                                    <Group list={elements} getValue={getValue} />
                                :   <Text list={elements} getValue={getValue} />;
                        })()}
                    {ssqProperty.field?.type === "group" && !ssqProperty.multivalue && (
                        <Group list={elements} getValue={getValue} />
                    )}
                    {ssqProperty.field?.type === "group" && ssqProperty.multivalue && (
                        <MultivalueGroup
                            list={elements}
                            headers={ssqProperty.children.map((child) => {
                                return {
                                    id: child.id,
                                    title: child.title,
                                    position: child.position,
                                };
                            })}
                            getValue={getValue}
                            multivalueScore={score}
                        />
                    )}
                </>
            }
        </WrapperStyled>
    );
};
