import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useProductContext } from "../ProductContext";
import { Loading } from "src/components/utils/Loading";
import { SummaryData } from "./SummaryData";
import { ScoreChart } from "src/components/ScoreChart/ScoreChart";
import { buildChartValues } from "src/utils/buildScoreChartValues";
import { useBuildSummaryData } from "./useBuildSummaryData";
import { WhiteBox } from "@foodpilot/foods";

const DIMENSION = "components";

export const Summary = () => {
    const { product, scoreState, allScoreWithVariations, productScores, switchToDimension, isLoading, isRefetching } =
        useProductContext();
    const summaryData = useBuildSummaryData();

    useEffect(() => {
        switchToDimension(DIMENSION);
    }, []);

    if (!product) return <Loading />;

    const selectedItemId = scoreState.lastSelectedVariation?.scoreId ?? allScoreWithVariations[0].id;

    const score = product?.scores[selectedItemId];
    const dimensionScores = score?.dimensions.filter((d) => d.label === DIMENSION).map((d) => d.values);

    const precision = productScores.find((ps) => ps.id === selectedItemId)?.precision ?? 2;

    const chartValues = buildChartValues(dimensionScores);

    const unit = productScores.find((ps) => ps.id === selectedItemId)?.unit?.abbreviation ?? "";

    return (
        <WhiteBox gap={4} px={4} py={3.5} sx={{ backgroundColor: "white" }} borderRadius={4}>
            <Stack gap={3} direction="row" justifyContent="space-between">
                <Stack flex={5} gap={3}>
                    <SummaryData data={summaryData} />
                </Stack>
                <Box flex={2}>
                    <ScoreChart
                        precision={precision}
                        allScoreWithVariations={allScoreWithVariations}
                        totalScore={chartValues.totalScore}
                        data={chartValues}
                        unit={unit}
                        isLoading={isLoading}
                        isRefetching={isRefetching}
                    />
                </Box>
            </Stack>
        </WhiteBox>
    );
};
