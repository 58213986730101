import { ButtonTabs, CustomToggleButton, FoodsIcon, IconMapping, Typography } from "@foodpilot/foods";
import { Stack } from "@mui/material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useProductContext } from "./ProductContext";
import { Loading } from "src/components/utils/Loading";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { URLHandler } from "src/services/URLHandler";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

type ProductQuestionnaireLink = {
    to: string;
    label: string;
    icon: keyof IconMapping;
};

export const ProductQuestionnaire = () => {
    const { ssq, isLoading, product } = useProductContext();
    const { selectedCompany } = useFoodpilotContext();
    const [navigate, location] = [useNavigate(), useLocation()];

    const path = location.pathname;
    const companyId = selectedCompany?.id;
    const productId = product?.id;

    const productQuestionnaireLinks: ProductQuestionnaireLink[] = useMemo(
        () =>
            companyId && productId ?
                [
                    {
                        to: URLHandler.product.ssqSummary(companyId, productId),
                        label: t("questionnaire.navigation.summary"),
                        icon: "questionnaires",
                    },
                    {
                        to: URLHandler.product.ssqProduct(companyId, productId),
                        label: t("questionnaire.navigation.product"),
                        icon: "products",
                    },
                    {
                        to: URLHandler.product.ssqIngredients(companyId, productId),
                        label: t("questionnaire.navigation.ingredients"),
                        icon: "ingredients",
                    },
                ]
            :   [],
        [companyId, productId],
    );

    const computeNavigationIndex = useCallback(() => {
        return productQuestionnaireLinks.findIndex((link) => path.endsWith(link.to)) || 0;
    }, [path, productQuestionnaireLinks]);

    const [defaultNavigationIndex, setDefaultNavigationIndex] = useState(0);

    useEffect(() => {
        setDefaultNavigationIndex(computeNavigationIndex());
    }, [computeNavigationIndex]);

    if (isLoading || !ssq) {
        return <Loading />;
    }

    if (ssq.monolithic) {
        return (
            <Stack gap={3}>
                <Outlet />
            </Stack>
        );
    }

    return (
        <Stack gap={3}>
            <ButtonTabs
                key={`product-questionnaire-navigation-${defaultNavigationIndex}`}
                choices={productQuestionnaireLinks.map((link, index) => (
                    <Stack
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        key={`product-questionnaire-navigation-link-${index + 1}`}
                    >
                        <FoodsIcon icon={link.icon} size={3} />
                        <Typography variant="h5" color={"inherit"}>
                            {link.label}
                        </Typography>
                    </Stack>
                ))}
                onClick={(index) => {
                    const selectedLink = productQuestionnaireLinks[index];
                    navigate(selectedLink.to);
                }}
                defaultIndex={defaultNavigationIndex}
            />
            <Stack>
                <Outlet />
            </Stack>
        </Stack>
    );
};
