import { RadioBoolean } from "@foodpilot/foods";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpsertAppParam } from "src/api/client-api/appParams";
import {
    ApplicationParameterKeys,
    useApplicationParameters,
} from "src/api/client-api/appParams/useApplicationParameters";

type BaseStringUploadProps = {
    appParamKey: ApplicationParameterKeys;
    label: string;
    description?: string;
};
export const BaseToggleUpload = (props: BaseStringUploadProps) => {
    const { appParamKey, label, description } = props;

    const defaultBooleanValue = false;
    const { applicationParameters } = useApplicationParameters();

    const upsertAppParam = useUpsertAppParam();
    const { t } = useTranslation();

    const _originalValue = applicationParameters?.[appParamKey];
    const originalValue =
        _originalValue === undefined ? defaultBooleanValue
        : _originalValue === "true" ? true
        : false;

    const [value, setValue] = useState<boolean>(originalValue);

    return (
        <Box sx={{ display: "flex", flexDirection: "row", height: "75px", gap: "16px" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", gap: "16px" }}>
                    <Typography variant="big-bold">{`${label}`}</Typography>
                    <Typography variant="big-bold">{`-`}</Typography>
                    <Typography variant="body-medium">{`${description}`}</Typography>
                </Box>
                <RadioBoolean
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                />
                <Button
                    sx={{ display: "flex", justifyContent: "left" }}
                    variant="text"
                    onClick={() => {
                        upsertAppParam.mutate({
                            isNew: _originalValue === undefined ? true : false,
                            key: appParamKey,
                            value: String(value),
                        });
                    }}
                >
                    {t("primaryDialog.validate")}
                </Button>
            </Box>
        </Box>
    );
};
