import { FoodsTextInput, LightIndicatorBlock, RichTextEditor, RoundInput } from "@foodpilot/foods";
import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ContentBlockForm } from "../../_Common/BlockForm/ContentBlock/contentBlock";

export type IndicatorBlock = {
    id: UUID | undefined;
    position: number;

    title: string;
    subtitle: string;
    description: string;
};

type IndicatorFormFieldsProps = {
    indicatorBlockForm: ContentBlockForm;
    setIndicatorBlockForm: (newArticle: ContentBlockForm) => void;
};
export const IndicatorFormFields = (props: IndicatorFormFieldsProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { indicatorBlockForm, setIndicatorBlockForm } = props;

    const subtitleForPreview = indicatorBlockForm.subtitle === "" ? undefined : indicatorBlockForm.subtitle;

    return (
        <Stack gap={theme.spacing(2)} padding={theme.spacing(2, 4)}>
            <LightIndicatorBlock
                title={indicatorBlockForm.title}
                subtitle={subtitleForPreview ?? undefined}
                description={indicatorBlockForm.description ?? ""}
            />
            <Stack>
                <Typography variant="body-medium">{t("glossary.title")}</Typography>
                <FoodsTextInput
                    value={indicatorBlockForm.title}
                    onChange={(newValue) => {
                        setIndicatorBlockForm({
                            ...indicatorBlockForm,
                            title: newValue,
                        });
                    }}
                />
            </Stack>
            <Stack>
                <Typography variant="body-medium">{t("glossary.helperText")}</Typography>
                <FoodsTextInput
                    value={indicatorBlockForm.subtitle ?? ""}
                    onChange={(newValue) => {
                        setIndicatorBlockForm({
                            ...indicatorBlockForm,
                            subtitle: newValue,
                        });
                    }}
                />
            </Stack>
            <Stack>
                <Typography variant="body-medium">{t("glossary.description")}</Typography>
                <RichTextEditor
                    key={`rich-text-editor-indicator-form-${indicatorBlockForm.id}`}
                    initialContent={indicatorBlockForm.description ?? undefined}
                    onChange={(newValue) => {
                        setIndicatorBlockForm({
                            ...indicatorBlockForm,
                            description: newValue,
                        });
                    }}
                />
            </Stack>
        </Stack>
    );
};
