import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FoodsIcon, SecondaryTab } from "@foodpilot/foods";
import { useNavigate } from "react-router-dom";
import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext.tsx";
import { ScoreDetail } from "src/components/ScoreDetail/ScoreDetail.tsx";
import { Loading } from "src/components/utils/Loading.tsx";
import { useIsVariationRefetching } from "src/utils/variationRefetching.ts";
import { useIngredientContext } from "src/pages/Ingredient/IngredientDetails/ExistingIngredientContext.tsx";
import { Distribution } from "src/pages/Ingredient/IngredientDetails/IngredientScores/SecondaryTabs/Distribution.tsx";

const IngredientScore = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { ingredient, variations, setVariations, isRefetching: isIngredientRefetching } = useIngredientContext();

    const { selectedScore } = useScoreDetailContext();
    const isVariationRefetching = useIsVariationRefetching(isIngredientRefetching, variations);

    if (!ingredient?.id) {
        throw new Error("No ingredient ID provided. Cannot fetch ingredient details.");
    }

    const getComponent = () => {
        if (isVariationRefetching) {
            return <Loading />;
        }

        return selectedScore ?
                <Distribution />
            :   <Typography variant="body-medium">{t("glossary.noScoreDetails")}</Typography>;
    };

    const tabs: SecondaryTab[] = [
        {
            id: 1,
            name: t("distribution_variant"),
            component: getComponent(),
            onClick: () => {
                navigate("?dimensions=headings");
            },
            icon: <FoodsIcon size={2} icon="repartition" />,
        },
    ];

    return (
        <ScoreDetail
            variations={variations}
            setVariations={setVariations}
            tabs={tabs}
            entity={{ name: "ingredient", id: ingredient.id }}
        />
    );
};

export default IngredientScore;
