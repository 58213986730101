import { ReactNode, useEffect } from "react";
import { Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { QuestionnairesList } from "src/components/QuestionnairesList/QuestionnairesList";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";
import { useGetProductPost } from "src/api/client-api/post/useGetProductPost";
import { useProductContext } from "../ProductContext";
import { Loading } from "src/components/utils/Loading";
import { ScoreChart } from "src/components/ScoreChart/ScoreChart";
import { Box } from "@mui/material";
import { buildChartValues } from "src/utils/buildScoreChartValues";
import { ScoreDimensionValue } from "src/api/client-api/post/scoreSchema";

const DIMENSION = "headings";

export const Questionnaire = (): ReactNode => {
    const {
        product,
        productScores,
        ssq,
        isSsqLoading,
        switchToDimension,
        scoreState,
        allScoreWithVariations,
        isLoading,
        isRefetching,
    } = useProductContext();
    const { selectedCompany, currentCampaign } = useFoodpilotContext();
    const companyId = selectedCompany ? selectedCompany.id : null;
    const { post, isLoading: isPostLoading } = useGetProductPost(product?.id, companyId, currentCampaign?.id);
    const selectedItemId = scoreState.lastSelectedVariation?.scoreId ?? allScoreWithVariations[0].id;
    const score = product?.scores[selectedItemId];
    // [ds] once the subheadings are implemented (PPD-1132) it can be uncommented to replace the dimensionScores below
    // const dimensionScores = score?.dimensions.filter((d) => d.label === DIMENSION).map((d) => d.values);
    const precision = productScores.find((ps) => ps.id === selectedItemId)?.precision ?? 2;

    const dimensionAllScores = score?.dimensions.filter((d) => d.label === DIMENSION).map((d) => d.values);
    const mainSsqHeadingsIds = ssq?.headings.map((h) => h.id.toString());

    const dimensionScores = dimensionAllScores?.map((values) => {
        return Object.keys(values).reduce((acc: Record<string, ScoreDimensionValue>, id: string) => {
            if (mainSsqHeadingsIds?.includes(id)) {
                acc[id] = values[id];
            }
            return acc;
        }, {});
    });

    useEffect(() => {
        switchToDimension(DIMENSION);
    }, []);

    if (!product) return <Loading />;
    if (selectedCompany === null) return;

    const chartValues = buildChartValues(dimensionScores);
    const unit = productScores.find((ps) => ps.id === selectedItemId)?.unit?.abbreviation ?? "";

    return (
        <QuestionnairesList
            ssq={ssq}
            post={post}
            isSsqLoading={isSsqLoading}
            isPostLoading={isPostLoading}
            displayDateLastModified={false}
            completeQuestionnaireUrl={(heading: Heading) => {
                const url = URLHandler.product.ssqHeading(selectedCompany.id, product.id, heading.id);
                return url;
            }}
            scoresSection={
                <Box>
                    <ScoreChart
                        precision={precision}
                        allScoreWithVariations={allScoreWithVariations}
                        totalScore={chartValues.totalScore}
                        data={chartValues}
                        unit={unit}
                        isLoading={isLoading}
                        isRefetching={isRefetching}
                    />
                </Box>
            }
            enableTableHead={false}
            muiTableProps={{
                sx: {
                    border: "none",
                },
            }}
            tableMode="extended"
        />
    );
};
