import { useEffect, useRef, useState } from "react";
import { Stack, Typography, Button, useTheme, Box, CircularProgress } from "@mui/material";
import { WhiteBox } from "@foodpilot/foods";
import { ArrowForward } from "@mui/icons-material";
import { ScoreValue } from "./ScoreValue";
import { useTranslation } from "react-i18next";
import { ComingSoonBadge } from "../ComingSoonBadge";
import { UnitSwitcher } from "./UnitSwitcher";
import { Score as SureScore } from "src/types";
import { Score, DeprecatedEmptyScore, doesScoreExist } from "src/api/client-api/post/scoreSchema";
import { ReferenceScore } from "src/types/applicationTypes/referenceScore.ts";
import { ScoreComparison } from "src/components/Score/ScoreComparison.tsx";
import { ScoreVariation } from "src/context/EntityBasedScore/ScoreService";

export type ScoreBoxProps = {
    score: SureScore;
    onClick?: () => void;
    value?: Score | null | DeprecatedEmptyScore;
    variations?: Record<number, number>;
    setVariations?: (variations: Record<number, number>) => void;
    isRefetching?: boolean;
    referenceScore?: ReferenceScore | null;
    prevScore?: Score | null | DeprecatedEmptyScore;
    prevCampaignName?: string;
    entity?: string;
    allScoreWithVariations?: ScoreVariation[];
    selectedVariations?: Record<number, number>;
};
export const ScoreBox = (props: ScoreBoxProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const isDeprecatedEmptyScore = Object.keys(props?.value || {}).length === 0;
    const isLinkActive = !isDeprecatedEmptyScore && props.onClick !== undefined;

    return (
        <WhiteBox sx={{ flex: "0 0 calc(33.33% - 14px)", minWidth: "fit-content" }}>
            <Box borderBottom={`1px solid ${theme.custom.grey[500]}`} sx={{ height: "70%" }}>
                <ScoreBoxCore {...props} />
            </Box>
            <Stack direction="row" alignItems="center" gap="28px" padding="28px 32px">
                <Button
                    // TODO: Re-enable when the page is hotfixed, tested and ready
                    disabled
                    variant="ternary"
                    onClick={isLinkActive ? props.onClick : undefined}
                    endIcon={<ArrowForward />}
                    sx={{
                        flex: 1,
                        justifyContent: "space-between",
                        minHeight: "40px",
                        border: `1px solid ${theme.custom.grey[500]}`,
                        borderRadius: "44px",
                        color: theme.custom.grey[2800],
                        fontWeight: 700,
                        padding: "0px 20px",
                        background: `linear-gradient(180deg, #FFF 0%, ${isLinkActive ? theme.palette.grey[300] : "#FAFAFA"}  100%)`,
                        pointerEvents: isLinkActive ? "initial" : "none",
                        opacity: isLinkActive ? 1 : 0.36,
                    }}
                >
                    {t("Voir plus de détail")}
                </Button>
                <ComingSoonBadge />
            </Stack>
        </WhiteBox>
    );
};

export const ScoreBoxCore = (props: ScoreBoxProps) => {
    const {
        score,
        value,
        variations = {},
        setVariations = () => {},
        isRefetching = false,
        referenceScore = null,
        prevScore = undefined,
        prevCampaignName = "",
        allScoreWithVariations,
        selectedVariations,
    } = props;

    const { t } = useTranslation();

    const [isVariationRefetching, setIsVariationRefetching] = useState<boolean>(isRefetching);
    const previousVariationsRef = useRef<Record<number, number>>(variations);

    useEffect(() => {
        if (!isRefetching) {
            setIsVariationRefetching(false);

            return;
        }

        // When the data is refetching and the variations didn't change, something else did (e.g. campaign) - we trigger the refetching state
        if (JSON.stringify(previousVariationsRef.current) === JSON.stringify(variations)) {
            setIsVariationRefetching(true);

            return;
        }

        const previousVariationId = previousVariationsRef.current[score.id];
        const currentVariationId = variations[score.id];

        setIsVariationRefetching(previousVariationId !== currentVariationId);

        previousVariationsRef.current = variations;
    }, [isRefetching, variations]);

    const scoreExists = value !== undefined && doesScoreExist(value);
    const prevScoreExists = prevScore !== undefined && doesScoreExist(prevScore);

    const currentVariationId = variations[score.id];
    const unit = scoreExists ? value.unit : score.unit?.abbreviation;

    const isReferenceScore =
        referenceScore !== null &&
        scoreExists &&
        referenceScore.value !== undefined &&
        !isVariationRefetching &&
        !currentVariationId;

    return (
        <Stack padding="28px 32px" paddingBottom="37px" gap="40px">
            <Stack gap="4px">
                <Typography variant="h3">{score.title}</Typography>

                {value?.unit && (
                    <UnitSwitcher
                        score={score}
                        value={value}
                        displayedVariations={variations}
                        setDisplayedVariations={setVariations}
                        allScoreWithVariations={allScoreWithVariations}
                        selectedVariations={selectedVariations}
                    />
                )}
            </Stack>

            <Stack>
                {!isVariationRefetching ?
                    <ScoreValue
                        score={score}
                        value={value}
                        sx={{
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "flex-end",
                        }}
                    />
                :   <CircularProgress />}
            </Stack>

            <Stack marginTop="-1rem">
                {prevScoreExists && scoreExists && !isVariationRefetching && (
                    <ScoreComparison
                        scoreValueToCompare={value.score}
                        referenceScoreValue={prevScore.score}
                        unit={unit}
                        reportName={prevCampaignName}
                        precision={score?.precision}
                    />
                )}
            </Stack>

            <Stack marginTop="-1rem">
                {isReferenceScore && (
                    <ScoreComparison
                        scoreValueToCompare={value.score}
                        referenceScoreValue={referenceScore.value ?? 0}
                        unit={score.unit?.abbreviation}
                        reportName={t("score_reference.the_reference")}
                        precision={score?.precision}
                    />
                )}
            </Stack>
        </Stack>
    );
};
