/*  eslint-disable @typescript-eslint/no-explicit-any --
Just doing that to silence the error, whole file is planned for DELETION once
INEXWEB is refactored to QSS */

import z from "zod";

export type RSEFormProps = {
    selectedAccount?: SelectedAccount;
    xCompany?: string;
    period?: Period;
    previousPeriod?: Period;
    siret?: string;
    company?: string;
    fiscalYear?: FiscalYear;
    previousFiscalYear?: FiscalYear;
    fiscalYearIndex?: number;
    energyTypes?: any[];
    categories: Category[];
};

export type ReportData = {
    xCompany: string;
    companyName?: string;
    frontCover: string;
    period: Period;
    previousPeriod: Period;
};

export type FiscalYear = {
    code: string;
    datestart: string;
    dateend: string;
    closed: 0 | 1;
};

export type Category = {
    label: string;
    energies: Energy[];
};

type Energy = {
    energyType: EnergyType;
    accounts: Account[];
};

export type EnergyType = {
    id: number;
    label: string;
    category: string;
    code: string;
};

export const EnergyTypeSchema = z
    .object({
        id: z.number(),
        label: z.string(),
        category: z.string(),
        code: z.string(),
    })
    .array()
    .brand("EnergyType");
type EnergyTypeType = z.infer<typeof EnergyTypeSchema>;

export const energyTypeZodToEnergyType = (value: EnergyTypeType): EnergyType[] => {
    return value;
};

type Account = {
    account: SelectedAccount;
    entries: Entry[];
    previousEntries: Entry[];
    total: number;
    previousTotal: number;
};

export type Accounting = {
    type: string;
    vat_id: number | null;
    vat_number: string | null;
    vat_rate: number;
    id: number;
    number: string;
    label: string;
};

export const AccountingSchema = z
    .object({
        type: z.string(),
        vat_id: z.number().nullable(),
        vat_number: z.string().nullable(),
        vat_rate: z.number(),
        id: z.number(),
        number: z.string(),
        label: z.string(),
    })
    .array()
    .brand("Accounting");
type AccountingType = z.infer<typeof AccountingSchema>;

export const accountingZodToAccounting = (value: AccountingType): Accounting[] => {
    return value;
};

export type Period = {
    datestart: string;
    dateend: string;
    headCount: number | null;
    turnover: number | null;
    production: number | null;
    entries: Entry[];
};

export type SelectedAccount = {
    id: string;
    label: string;
};

export type Entry = {
    type: string;
    subtype: string;
    header: string;
    number: string | null;
    book: string;
    period: string;
    day: number;
    account: string;
    account_type: string;
    label: string;
    debit: number;
    credit: number;
};
export type UncategorizedEntry = Omit<Entry, "type" | "subtype">;
export type AccountEntries = { entries: UncategorizedEntry[] };

export const AccountEntriesSchema = z
    .object({
        entries: z
            .object({
                header: z.string(),
                number: z.string().nullable(),
                book: z.string(),
                period: z.string(),
                day: z.number(),
                account: z.string(),
                account_type: z.string(),
                label: z.string(),
                debit: z.number(),
                credit: z.number(),
            })
            .array(),
    })
    .brand("AccountEntries");
type AccountEntriesType = z.infer<typeof AccountEntriesSchema>;

export const accountEntriesZodToAccountEntries = (value: AccountEntriesType): AccountEntries => {
    return value;
};

export type FiscalYears = {
    exercices: FiscalYear[];
};

export const FiscalYearsSchema = z
    .object({
        exercices: z
            .object({
                code: z.string(),
                datestart: z.string(),
                dateend: z.string(),
                closed: z.union([z.literal(0), z.literal(1)]),
            })
            .array(),
    })
    .brand("FiscalYears");
type FiscalYearsType = z.infer<typeof FiscalYearsSchema>;

export const fiscalYearsZodToFiscalYears = (value: FiscalYearsType): FiscalYears => {
    return value;
};

export type ReportMutation = { parameters: ReportData; externalAccountId: string; user: string };
export type Report = { filename: string };

export const ReportSchema = z
    .object({
        filename: z.string(),
    })
    .brand("Report");
type ReportType = z.infer<typeof ReportSchema>;

export const reportZodToReport = (value: ReportType): Report => {
    return value;
};
