import { StepProps } from "@foodpilot/foods";
import { doesScoreExist, ScoreDimensionValue, ScoreOrEmpty, Scores } from "src/api/client-api/post/scoreSchema";
import { Score } from "src/types";

export type ScoreVariation = {
    id: number;
    label: string;
    group: string;
    scoreId: number;
    variationId: number | null;
    unit: string;
    onClick: () => void;
    precision?: number;
};

export type ScoreByProperties = Partial<Record<string, ScoreDimensionValue>>;

export class ScoreService {
    public static getAllScoreVariations(
        scoresEnabled: Score[],
        entityScores: Scores | undefined,
        scoreAction?: (scoreId: number, scoreVariationId: number | null) => void,
    ): ScoreVariation[] {
        const allScoreWithVariations = scoresEnabled.flatMap((score) => {
            const firstScoreBlock: ScoreVariation = {
                id: score.id,
                scoreId: score.id,
                variationId: null,
                label: score.label ?? "",
                group: score.title,
                unit: score.unit?.abbreviation ?? "",
                onClick: () => {
                    scoreAction?.(score.id, null);
                },
                precision: score.precision,
            };

            const entityScore = entityScores?.[score.id];
            const entityVariations = entityScore?.variations ?? [];
            const scoreVariations: ScoreVariation[] = entityVariations.map((scoreVariation) => {
                return {
                    id: scoreVariation.id,
                    scoreId: score.id,
                    variationId: scoreVariation.id,
                    label: scoreVariation.label,
                    group: score.title,
                    unit: scoreVariation.unit,
                    onClick: () => {
                        scoreAction?.(score.id, scoreVariation.id);
                    },
                };
            });
            const opts = [firstScoreBlock, ...scoreVariations];
            return opts;
        });

        return allScoreWithVariations;
    }

    public static getScoreProperties = (scoreId: number | undefined, scores: Scores): ScoreByProperties => {
        if (scoreId === undefined) {
            return {};
        }
        const scoreProperties = scores?.[scoreId];
        if (scoreProperties !== undefined && doesScoreExist(scoreProperties)) {
            const foundProperties = scoreProperties.dimensions.find((dimension) => dimension.label === "properties");
            if (foundProperties !== undefined) {
                return foundProperties.values;
            }
        }

        return {};
    };

    public static substractScore(startScore: number | null, endScore: number | null): number | null {
        if (endScore === null && startScore === null) {
            return null;
        }

        if (endScore === null && startScore !== null) {
            // Return * -1 ?
            // To negate it, and say :
            // if my start is 10, and current is unknown, then diff is -10;
            return startScore;
        }

        if (endScore !== null && startScore === null) {
            // Weird case, but eh.
            return endScore;
        }

        if (endScore !== null && startScore !== null) {
            return endScore - startScore;
        }

        return null;
    }

    public static addScore(base: number | null, toAdd: number | null): number | null {
        if (toAdd === null && base === null) {
            return null;
        }

        if (toAdd === null && base !== null) {
            return base;
        }

        if (toAdd !== null && base === null) {
            return toAdd;
        }

        if (toAdd !== null && base !== null) {
            return toAdd + base;
        }

        return null;
    }

    public static getScore(
        score: ScoreOrEmpty | undefined,
        campaign: string,
        precision: number | undefined,
        unit: string | undefined,
    ): StepProps {
        if (score === undefined || !doesScoreExist(score)) {
            return {
                year: campaign,
                value: null,
                unit: unit ?? "",
                incomplete: false,
            };
        }

        const roundedScore = Number(score.score.toFixed(precision));
        return {
            year: campaign,
            value: roundedScore,
            unit: score.unit,
            incomplete: false,
        };
    }
}
