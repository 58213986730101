import { Ingredient } from "src/api/client-api/products/useGetOneProduct";
import { IngredientPost } from "src/api/client-api/post/useGetSomeIngredientPosts";

interface ProductIngredient {
    ingredient: Ingredient;
}

export class IngredientCompletionService {
    private static supplierCompletionRates(
        ingredient: Ingredient | undefined | null,
        ingredientPosts: IngredientPost[],
    ): number[] {
        if (!ingredient) {
            return [];
        }

        const filteredPosts = ingredientPosts.filter((post) => post.ingredient?.id === ingredient.id);

        if (!ingredient.ingredientSuppliers?.length) {
            return [];
        }

        return ingredient.ingredientSuppliers.map((ingredientSupplier) => {
            if (!ingredientSupplier?.supplier?.id) {
                return 0;
            }
            const companyMatch = filteredPosts.find((post) => post.company?.id === ingredientSupplier.supplier.id);
            const result = companyMatch?.completions?.ssq?.result ?? 0;
            return result;
        });
    }

    private static getSupplierQuestionnaireFilled(
        ingredient: Ingredient | undefined | null,
        ingredientPosts: IngredientPost[],
    ): number {
        const rates = this.supplierCompletionRates(ingredient, ingredientPosts);
        const filled = rates.reduce((acc, rate) => (rate === 100 ? acc + 1 : acc), 0);
        return filled;
    }

    private static completionRate(
        ingredient: Ingredient | undefined | null,
        ingredientPosts: IngredientPost[],
    ): number {
        const rates = this.supplierCompletionRates(ingredient, ingredientPosts);
        if (!rates.length) {
            return 0;
        }
        const total = rates.reduce((a, c) => a + c, 0);
        const rate = total / rates.length;
        return rate;
    }

    private static calculateCompletionRates(
        ingredient: Ingredient | undefined | null,
        ingredientPosts: IngredientPost[] | undefined | null,
    ) {
        if (!ingredient || !ingredientPosts?.length) {
            return { totalCompletionRate: 0, totalIngredientCompletion: 0 };
        }

        const totalCompletionRate = this.completionRate(ingredient, ingredientPosts);
        const totalIngredientCompletion = totalCompletionRate;

        return {
            totalCompletionRate,
            totalIngredientCompletion,
            supplierQuestionnaireFilled: this.getSupplierQuestionnaireFilled(ingredient, ingredientPosts),
        };
    }

    public static calculateTotalCompletionRates(
        productIngredients: ProductIngredient[] | undefined | null,
        ingredientPosts: IngredientPost[] | undefined | null,
    ) {
        if (!productIngredients?.length || !ingredientPosts?.length) {
            return { totalCompletionRate: 0, totalIngredientCompletion: 0 };
        }

        const ingredientRates = productIngredients.map((pi) => {
            const { totalCompletionRate, supplierQuestionnaireFilled } = this.calculateCompletionRates(
                pi.ingredient,
                ingredientPosts,
            );
            return { totalCompletionRate, supplierQuestionnaireFilled };
        });

        const totalCompletionRate = ingredientRates.reduce((sum, rate) => sum + rate.totalCompletionRate, 0);
        const totalIngredientCompletion = totalCompletionRate / productIngredients.length;
        const totalSupplierQuestionnaireFilled = ingredientRates.reduce(
            (sum, rate) => sum + (rate.supplierQuestionnaireFilled ?? 0),
            0,
        );

        return {
            totalCompletionRate,
            totalIngredientCompletion,
            supplierQuestionnaireFilled: totalSupplierQuestionnaireFilled,
        };
    }
}
