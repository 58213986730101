import { WhiteBox } from "@foodpilot/foods";
import { Stack, useTheme } from "@mui/material";
import { ReactNode } from "react";

type EditLayoutProps = {
    children: ReactNode;
};
export const EditLayout = (props: EditLayoutProps) => {
    const { children } = props;
    const theme = useTheme();

    return (
        <Stack gap="28px" sx={{ padding: "40px 52px" }}>
            <WhiteBox>
                <Stack gap={theme.spacing(4)} padding={theme.spacing(2, 4)}>
                    {children}
                </Stack>
            </WhiteBox>
        </Stack>
    );
};
