import { Typography } from "@foodpilot/foods";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Post } from "src/api/client-api/post/postSchema.ts";
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, Box, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useLockUnlockPost } from "src/api/client-api/post/updatePost.ts";
import { useSnackbar } from "src/utils/useSnackbar.tsx";

type AddIndicatorModalProps = {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setLocked: Dispatch<SetStateAction<boolean>>;
    isLocked: boolean;
    post: Post;
};
export const LockPostModal = (props: AddIndicatorModalProps) => {
    const { isOpen, setOpen, post, setLocked, isLocked } = props;
    const { t } = useTranslation();
    const snackbar = useSnackbar();
    const lockUnlockPost = useLockUnlockPost();

    const onCloseAndReset = () => {
        setOpen(false);
    };

    const onConfirm = () => {
        lockUnlockPost.mutate(
            { postId: post.id, isLocked: isLocked },
            {
                onSuccess: () => {
                    setOpen(false);
                    snackbar.forceClose();
                    snackbar.setSnackbarProps({
                        type: "success",
                        message:
                            isLocked ?
                                t("questionnaire.unlock-questionnaire.snackbar.success")
                            :   t("questionnaire.lock-questionnaire.snackbar.success"),
                    });
                    setLocked(!isLocked);
                },
            },
        );
    };

    return (
        <Dialog maxWidth="xs" open={isOpen}>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h3">
                        {isLocked ?
                            t("questionnaire.unlock-questionnaire.modal_title")
                        :   t("questionnaire.lock-questionnaire.modal_title")}
                    </Typography>
                    <IconButton onClick={onCloseAndReset} edge="end" size="small" aria-label="close">
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body-medium">
                    {isLocked ?
                        t("questionnaire.unlock-questionnaire.modal_body")
                    :   t("questionnaire.lock-questionnaire.modal_body")}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Box display="flex" flexDirection="column" width="100%" gap={1} px={2} pb={2}>
                    <Button onClick={onConfirm} variant="primary">
                        {isLocked ?
                            t("questionnaire.lock-questionnaire.modal_button_confirm_unlock")
                        :   t("questionnaire.lock-questionnaire.modal_button_confirm_lock")}
                    </Button>
                    <Button onClick={onCloseAndReset} variant="ternary">
                        {isLocked ?
                            t("questionnaire.lock-questionnaire.modal_button_cancel_unlock")
                        :   t("questionnaire.lock-questionnaire.modal_button_cancel_lock")}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
