import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConfirmationFooter } from "../../CMS/Pages/Sections/_Common/ConfirmationFooter";
import { useState } from "react";
import { CsrStrategy } from "src/api/client-api/csrStrategy/useGetAllStrategy";
import { PayloadWrite, useUpsertStrategy } from "src/api/client-api/csrStrategy/useUpsertStrategy";
import { FoodsSelect, FoodsTextInput, WhiteBox } from "@foodpilot/foods";
import { useSettingsStrategy } from "src/context/Settings/CsrStrategy/useSettingsStrategy";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { useSnackbar } from "src/utils/useSnackbar";

const formToStrategyWrite = (strategyForm: StrategyForm): PayloadWrite => {
    const strategyPayload: PayloadWrite = {
        id: strategyForm.id,
        name: strategyForm.name,
        description: strategyForm.description ?? "",
        campaign: strategyForm.campaign ?? "",
    };
    return strategyPayload;
};

const strategyToForm = (strategy?: CsrStrategy): StrategyForm => {
    if (strategy === undefined) {
        const defaultForm: StrategyForm = {
            id: undefined,
            name: "",
            description: "",
            campaign: null,
        };
        return defaultForm;
    }
    const strategyForm: StrategyForm = {
        id: strategy.id,
        name: strategy.name,
        description: strategy.description ?? null,
        campaign: strategy.campaign,
    };
    return strategyForm;
};

type StrategyForm = {
    id: string | undefined;
    name: string;
    description: string | null;
    campaign: IRI | null;
};

export const GeneralInfo = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { strategy } = useSettingsStrategy();
    const snackbar = useSnackbar();
    const { campaigns } = useFoodpilotContext();
    const upsertStrategy = useUpsertStrategy();

    const _initStrategy = strategyToForm(strategy);
    const [strategyForm, setStrategyForm] = useState(_initStrategy);

    const selectedCampaign = campaigns.find((c) => c["@id"] === strategyForm.campaign);
    return (
        <WhiteBox>
            <Stack gap={theme.spacing(4)} padding={theme.spacing(2, 4)}>
                <Stack maxWidth={"500px"} gap={theme.spacing(3)}>
                    <Typography variant="huge-bold">{t("settings.csr.info.title")}</Typography>
                    <Stack>
                        <Typography variant="big-medium">{t("settings.csr.info.fields.name.label")}</Typography>
                        <FoodsTextInput
                            value={strategyForm.name}
                            onChange={(newValue) => {
                                setStrategyForm({
                                    ...strategyForm,
                                    name: newValue,
                                });
                            }}
                            ExtraInputProps={{
                                placeholder: t("settings.csr.info.fields.name.placeholder"),
                            }}
                        />
                    </Stack>
                    <Stack>
                        <Typography variant="big-medium">{t("settings.csr.info.fields.description.label")}</Typography>
                        <FoodsTextInput
                            value={strategyForm.description ?? ""}
                            onChange={(newValue) => {
                                setStrategyForm({
                                    ...strategyForm,
                                    description: newValue,
                                });
                            }}
                            ExtraInputProps={{
                                placeholder: t("settings.csr.info.fields.description.placeholder"),
                            }}
                        />
                    </Stack>
                    <Stack>
                        <Typography variant="big-medium">{t("settings.csr.info.fields.campaign.label")}</Typography>
                        <FoodsSelect
                            selectedOption={selectedCampaign ?? null}
                            options={campaigns}
                            onChange={(selectedValue) => {
                                setStrategyForm({
                                    ...strategyForm,
                                    campaign: selectedValue?.["@id"] ?? null,
                                });
                            }}
                            getId={(item) => item.id}
                            getName={(item) => item.label}
                            textOptions={{
                                placeholder: t("actions.noSelectedCampaign"),
                                searchPlaceholder: "",
                                emptyOptionsText: "",
                                defaultValueText: undefined,
                                noResult: "",
                            }}
                        />
                    </Stack>
                </Stack>
                <ConfirmationFooter
                    saveAction={() => {
                        const dataToWrite = formToStrategyWrite(strategyForm);
                        upsertStrategy.mutate(
                            { payload: dataToWrite },
                            {
                                onSuccess: () => {
                                    snackbar.forceClose();
                                    snackbar.setSnackbarProps({
                                        type: "info",
                                        message: t("settings.success"),
                                    });
                                },
                            },
                        );
                    }}
                />
            </Stack>
        </WhiteBox>
    );
};
