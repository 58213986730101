import { Stack, Typography, useTheme } from "@mui/material";
import { CsrIndicatorForm } from "../PillarForm";
import { FoodsTextInput } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";

type IndicatorFieldsProps = {
    indicator: CsrIndicatorForm;
    setIndicator: (newIndicators: CsrIndicatorForm) => void;
};
export const IndicatorFields = (props: IndicatorFieldsProps) => {
    const { indicator, setIndicator } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Stack padding={theme.spacing(2, 4)} gap={theme.spacing(2)} width={"40%"}>
            <Stack>
                <Typography variant="big-medium">{t("settings.csr.form.indicator.fields.name") + "*"}</Typography>
                <FoodsTextInput
                    value={indicator.name}
                    onChange={(newValue) => {
                        setIndicator({
                            ...indicator,
                            name: newValue,
                        });
                    }}
                />
            </Stack>
            <Stack>
                <Typography variant="big-medium">{t("settings.csr.form.indicator.fields.unit")}</Typography>
                <FoodsTextInput
                    value={indicator.abbreviationUnit}
                    onChange={(newValue) => {
                        setIndicator({
                            ...indicator,
                            abbreviationUnit: newValue,
                        });
                    }}
                />
            </Stack>
            <Stack>
                <Typography variant="big-medium">
                    {t("settings.csr.form.indicator.fields.currentValue") + "*"}
                </Typography>
                <FoodsTextInput
                    value={indicator.currentValue}
                    onChange={(newValue) => {
                        setIndicator({
                            ...indicator,
                            currentValue: newValue,
                        });
                    }}
                />
            </Stack>
            <Stack>
                <Typography variant="big-medium">
                    {t("settings.csr.form.indicator.fields.shortTermGoal") + "*"}
                </Typography>
                <FoodsTextInput
                    value={indicator.shortTermTarget}
                    onChange={(newValue) => {
                        setIndicator({
                            ...indicator,
                            shortTermTarget: newValue,
                        });
                    }}
                />
            </Stack>
            <Stack>
                <Typography variant="big-medium">
                    {t("settings.csr.form.indicator.fields.longTermGoal") + "*"}
                </Typography>
                <FoodsTextInput
                    value={indicator.longTermTarget}
                    onChange={(newValue) => {
                        setIndicator({
                            ...indicator,
                            longTermTarget: newValue,
                        });
                    }}
                />
            </Stack>
        </Stack>
    );
};
