import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Answer, GetValueType } from "src/components/ScoreDetail/Responses/ResponsesModal";

const StackStyled = styled(Stack)({
    "&:not(:last-of-type)": {
        borderBottom: "1px dashed #e3e2e0",
        marginBottom: "28px",
        paddingBottom: "28px",
    },
});

type GroupProps = {
    list: Answer[];
} & GetValueType;
export const Group = (props: GroupProps) => {
    const { list, getValue } = props;

    const pairs = list.reduce<Answer[][]>((result, _, index) => {
        if (index % 2 === 0) {
            result.push(list.slice(index, index + 2));
        }
        return result;
    }, []);

    return pairs.map((pair, index) => {
        return (
            <StackStyled
                key={index}
                direction="row"
                sx={{ width: "100%" }}
                divider={
                    <Box
                        sx={{
                            mx: "28px",
                            display: "flex",
                        }}
                    >
                        <Box
                            sx={{
                                borderRight: "1px dashed #e3e2e0",
                                marginLeft: "10px",
                            }}
                        />
                    </Box>
                }
            >
                {pair.map((postElement, i) => {
                    return (
                        <Box width="50%" key={i}>
                            {postElement.name && (
                                <Typography
                                    variant="h5"
                                    component="h5"
                                    fontWeight={700}
                                    sx={{
                                        width: "100%",
                                        mb: "20px",
                                    }}
                                >
                                    {postElement.name}:
                                </Typography>
                            )}
                            <Typography
                                variant="body"
                                component="span"
                                fontWeight={600}
                                fontSize="20px"
                                mr="8px"
                                sx={{
                                    wordBreak: "break-word",
                                }}
                            >
                                {getValue(postElement)}
                            </Typography>
                            {postElement.unitAbbreviation && (
                                <Typography
                                    variant="body"
                                    component="span"
                                    sx={(theme) => ({
                                        color: theme.custom.grey[1400],
                                        fontSize: "12px",
                                        fontWeight: 500,
                                    })}
                                >
                                    {postElement.unitAbbreviation}
                                </Typography>
                            )}
                        </Box>
                    );
                })}
            </StackStyled>
        );
    });
};
