import { useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { FoodsToggleButton } from "@foodpilot/foods";
import { useVariableTranslation } from "src/utils/i18n.ts";
import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext.tsx";

type ScoreDetailGlossaryProps = {
    baseUrl: string;
    dimensionOrder: string[];
    additionalDimensions?: string;
    additionalDimensionValueName?: string;
};
export const ScoreDetailGlossary = (props: ScoreDetailGlossaryProps) => {
    const { dimensionOrder = [], additionalDimensions, baseUrl } = props;

    const { getMainScore } = useScoreDetailContext();
    const [searchParams] = useSearchParams();
    const trans = useVariableTranslation();
    const navigate = useNavigate();

    const urlRequestedDimension = new URLSearchParams(searchParams).get("dimensions");
    const mainDimensions = getMainScore()?.dimensions.filter((dimension) => dimension.label !== additionalDimensions);
    const sortedDimensions = mainDimensions?.sort(
        (a, b) => dimensionOrder.indexOf(a.label) - dimensionOrder.indexOf(b.label),
    );

    const glossaryButtons =
        sortedDimensions?.map((dimension) => {
            if (!baseUrl) {
                console.warn("Correct baseUrl is not defined in ScoreDetailGlossary component");
            }

            return {
                id: dimension.label,
                label: trans(`glossary.dimensions.${dimension.label}`, dimension.label),
                onClick: () => {
                    navigate(`${baseUrl}?dimensions=${dimension.label}`);
                },
            };
        }) ?? [];

    const findDefaultGlossaryButtonIndex = (): number => {
        if (!urlRequestedDimension) {
            return 0;
        }

        const selected = glossaryButtons.findIndex((button) => button.id === urlRequestedDimension);
        return selected === -1 ? 0 : selected;
    };

    return (
        glossaryButtons.length > 1 && (
            <Box display="flex" sx={{ mb: "24px", "& > div": { display: "flex", gap: "16px" } }}>
                <FoodsToggleButton
                    buttons={glossaryButtons}
                    initialSelected={glossaryButtons[findDefaultGlossaryButtonIndex()]}
                />
            </Box>
        )
    );
};
