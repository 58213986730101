import { Box, Button, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { FoodsIcon } from "@foodpilot/foods";
import { useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { Property } from "src/api/sure-api/ssq/useGetOneSsq.ts";
import { useGetOneSsqProperty } from "src/api/sure-api/ssq/useGetOneSsqProperty.ts";
import { Loading } from "src/components/utils/Loading.tsx";
import { ScoreDimensionValue } from "src/api/client-api/post/scoreSchema.ts";
import { formatUnitAbbreviation } from "src/utils/formatting.ts";
import { Post } from "src/api/client-api/post/postSchema.ts";
import { ResponsesModalContent } from "src/components/ScoreDetail/Responses/ResponsesModalContent.tsx";
import { getNumberOfSuppliers } from "src/pages/Ingredient/IngredientDetails/IngredientScores.tsx";
import { IngredientSupplier } from "src/api/client-api/ingredients/useGetOneIngredient";

const DialogStyled = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: "28px",
        background: theme.custom.grey[200],
    },
}));

export type Answer = {
    ssqPropertyId: number;
    name: string;
    value: string;
    unitAbbreviation?: string;
    position: number;
};

export type DataLists = Record<number, Map<string, string>>;

export type GetValueType = {
    getValue: (element: Answer) => string;
};

export const createDataList = (property: Property[], map: DataLists = {}): Record<number, Map<string, string>> => {
    const list = map || {};

    return property.reduce((prev, property) => {
        const { id, field, dataList, children } = property;

        if (
            field?.type !== undefined &&
            dataList !== undefined &&
            ["select", "checkbox", "radio"].includes(field.type)
        ) {
            prev[id] = new Map<string, string>();

            dataList.options.forEach((option) => {
                prev[id].set(option.value, option.label);
            });
        }

        if (Array.isArray(children) && children.length > 0) {
            createDataList(children, list);
        }

        return prev;
    }, list);
};

const getRequestedElements = (ssqProperty: Property, post?: Post): Answer[] => {
    if (!post) {
        return [];
    }

    const itemsIds: number[] = [];
    const children: { [key: number]: Property } = {};

    if (ssqProperty.children.length > 0) {
        ssqProperty.children.forEach((child) => {
            itemsIds.push(child.id);
            children[child.id] = child;
        });
    }

    if (itemsIds.length === 0 && ssqProperty.field?.type !== "group") {
        itemsIds.push(ssqProperty.id);
    }

    return (post?.elements || [])
        .filter((element) => itemsIds.includes(element.ssqPropertyId))
        .map((element) => {
            const childSsqProperty = children[element.ssqPropertyId];
            const unit = formatUnitAbbreviation(childSsqProperty ? childSsqProperty.unit : ssqProperty.unit);

            return {
                name: String(element.name),
                value: element.value,
                unitAbbreviation: unit,
                ssqPropertyId: element.ssqPropertyId,
                position: element.position,
            };
        });
};

const DialogContentStyled = styled(DialogContent)({
    display: "flex",
    flexWrap: "wrap",
    "& > .MuiStack-root": {
        borderBottom: "1px dashed #e3e2e0",
        "&:first-of-type": {
            "& > .MuiBox-root": {
                paddingTop: 0,
            },
        },
        "&:last-child": {
            borderBottom: "0 none",
            "& > .MuiBox-root": {
                paddingBottom: 0,
            },
        },
    },
});

type DistributionDetailsResponsesModalProps = {
    title: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    posts?: Post[];
    propertyId: number;
    score?: ScoreDimensionValue;
    onModifyAnswersClick: () => void;
    suppliers?: IngredientSupplier[];
};
export const ResponsesModal = (props: DistributionDetailsResponsesModalProps) => {
    const { t } = useTranslation();
    const { title, open, setOpen, propertyId, score, posts, suppliers, onModifyAnswersClick } = props;

    const { currentCampaign } = useFoodpilotContext();
    const { ssqProperty, isLoading } = useGetOneSsqProperty(propertyId);

    const handleClose = () => {
        setOpen(false);
    };

    const suppliersCount = getNumberOfSuppliers(suppliers);

    return (
        <DialogStyled
            onClose={handleClose}
            open={open}
            PaperProps={{ sx: { borderRadius: "12px", width: "100%", maxWidth: "640px", maxHeight: "550px" } }}
        >
            <Box sx={{ m: 0, p: "20px 28px", position: "relative" }}>
                <Typography variant="h3" component="h3" mb="4px" sx={{ maxWidth: "92%" }}>
                    {title}
                </Typography>
                {currentCampaign && (
                    <Typography variant="body" component="p">
                        {t("campaign")} - {currentCampaign?.label}
                    </Typography>
                )}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: "absolute",
                        right: 20,
                        top: "50%",
                        transform: "translateY(-50%)",
                        bottom: 0,
                        color: theme.custom.grey[2800],
                    })}
                >
                    <FoodsIcon size={4} icon="close" />
                </IconButton>
            </Box>
            <DialogContentStyled dividers>
                {(() => {
                    if (isLoading || ssqProperty === undefined) {
                        return <Loading />;
                    }

                    if (suppliersCount > 1) {
                        return suppliers?.map(({ supplier }) => {
                            const post = posts?.find((post) => post.company?.id === supplier.id);
                            const elements = getRequestedElements(ssqProperty, post);

                            return (
                                <ResponsesModalContent
                                    key={supplier.id}
                                    header={supplier.name}
                                    elements={elements}
                                    ssqProperty={ssqProperty}
                                    score={score}
                                />
                            );
                        });
                    }

                    const elements = getRequestedElements(ssqProperty, posts?.[0]);

                    return <ResponsesModalContent elements={elements} ssqProperty={ssqProperty} score={score} />;
                })()}
            </DialogContentStyled>
            <Box sx={{ display: "flex", p: "20px 28px", alignItems: "center", justifyContent: "flex-end" }}>
                {suppliersCount < 2 && (
                    <Button variant="ternary" onClick={onModifyAnswersClick} sx={{ mr: "12px" }}>
                        {t("questionnaire.modify_answers")}
                    </Button>
                )}
                <Button variant="primary" onClick={handleClose}>
                    {t("glossary.close")}
                </Button>
            </Box>
        </DialogStyled>
    );
};
