import { FoodsTextInput, FoodsTextInputProps, FormUnit } from "@foodpilot/foods";
import { Fragment } from "react/jsx-runtime";
import { FormTooltip } from "src/components/FormProperty/FormTooltip";

type Score = {
    score: number;
    unit: string;
};

type SSQTextInputProps = FoodsTextInputProps & {
    score: Score | null;
    tooltipText?: JSX.Element;
    onCalculate?: (valueToUse: string) => void;
};
export const SSQTextInput = (props: SSQTextInputProps) => {
    const {
        tooltipText,
        score,
        onCalculate,

        // Props from FoodsTextInputProps that we override here
        ExtraInputProps = {},
        defaultOption,

        ...FoodsTextInputProps
    } = props;

    const isNumber = ExtraInputProps.type === "number";
    return (
        <FormTooltip title={tooltipText} arrow placement="right">
            <Fragment>
                <FoodsTextInput
                    {...FoodsTextInputProps}
                    defaultOption={
                        defaultOption === undefined ? defaultOption : (
                            {
                                ...defaultOption,
                                extraAction: (defaultValue) => onCalculate?.(defaultValue),
                            }
                        )
                    }
                    ExtraInputProps={{
                        ...ExtraInputProps,
                        onBlur: (e) => {
                            const relatedTarget = e.nativeEvent.relatedTarget as Element;
                            const isTargetPopover =
                                relatedTarget && relatedTarget.classList.contains("MuiPopover-paper");

                            if (e.nativeEvent.type === "focusout" && isTargetPopover) {
                                return;
                            }

                            onCalculate?.(FoodsTextInputProps.value);
                        },
                        onWheel:
                            isNumber === false ? undefined : (
                                (e) => {
                                    // Kilian : The any type here is to help the typeinference of `.blur()` and `.focus()`.
                                    // We need to find a way, because the code works. But the type inference doesn't.

                                    const target = e.target as HTMLElement;
                                    // Prevent the input value change
                                    target.blur();

                                    // Prevent the page/container scrolling
                                    e.stopPropagation();

                                    // Refocus immediately, on the next tick (after the current
                                    // function is done)
                                    setTimeout(() => {
                                        target.focus();
                                    }, 0);
                                }
                            ),
                    }}
                />
                {score !== null && <FormUnit value={score.score} unit={score.unit} />}
            </Fragment>
        </FormTooltip>
    );
};
