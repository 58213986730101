import React from "react";
import { UpdateProperty } from "src/api/client-api/property/useUpdateProperty";
import { DeleteProperty } from "src/api/client-api/property/useDeleteProperty";
import { FormPropertyContainer, FormPropertyContainerProps } from "src/components/FormProperty/FormPropertyContainer";

type PropertyContainerProps = FormPropertyContainerProps & {
    onUpdate: (data: UpdateProperty) => void;
    onDelete: (data: DeleteProperty) => void;
};
export const PropertyContainer = (props: PropertyContainerProps) => {
    const { property, post, formattedScores, onUpdate, onDelete, canEdit } = props;

    const onPropertyUpdate = (data: UpdateProperty) => {
        if (data.value === undefined || data.value === "") {
            data.value = "_empty_";
        }

        onUpdate(data);
    };

    const onPropertyDelete = (data: DeleteProperty) => {
        onDelete(data);
    };

    return (
        <FormPropertyContainer
            property={property}
            post={post}
            onUpdate={onPropertyUpdate}
            onDelete={onPropertyDelete}
            formattedScores={formattedScores}
            canEdit={canEdit && !post?.isLocked}
        />
    );
};
