import { Product } from "src/api/client-api/products/useGetOneProduct";
import { Divider } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { useIngredientPosts } from "src/api/client-api/ingredients/useIngredientPosts";
import { IngredientsDataRow } from "./IngredientsDataRow";

type IngredientDataProps = {
    product: Product | undefined;
    scoreId: number;
    unit: string;
};

export const IngredientsData = ({ product, scoreId, unit }: IngredientDataProps) => {
    const ingredientIds = product?.productIngredients?.map((p) => p.ingredient.id) || [];
    const postQueries = useIngredientPosts(ingredientIds);

    return product?.productIngredients?.map((pi, index) => {
        // Extract the actual post data from the query results
        const relevantPosts = postQueries
            ?.filter((query) => query.data?.data)
            .map((query) => query.data?.data)
            .filter((post): post is NonNullable<typeof post> => post !== undefined);

        return (
            <Fragment key={`ingredient-data-row-${pi.ingredient.id}`}>
                <IngredientsDataRow
                    ingredient={pi.ingredient}
                    scoreId={scoreId}
                    unit={unit}
                    posts={relevantPosts || []}
                />
                {index < (product?.productIngredients?.length ?? 0) - 1 && <Divider />}
            </Fragment>
        );
    });
};
