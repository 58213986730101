import { FormattedScores } from "../../types";
import { Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { Stack, Typography, useTheme } from "@mui/material";
import { DoughnutChart, DoughnutChartProps } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils/formatting";
import { getFormattedScoreByScoreId } from "src/utils/ssq";

type ScoresChartProps = DoughnutChartProps & {
    formattedScores?: FormattedScores;
    headings?: Heading[];
    displayedScoreId?: number;
    displayedVariationId?: number;
    precision?: number;
};

/**
 * @remarks
 * Legacy ScoresChart component that uses `formattedScores` , from `2025-01-15` this usage is deprecated.
 * in order to display the precise data.
 *
 * @see ScoreChart located @ `src/components/ScoreChart`
 */

export const ScoresChart = (props: ScoresChartProps) => {
    const {
        formattedScores = {},
        displayedScoreId = 0,
        displayedVariationId = 0,
        headings = [],
        precision,
        ...doughnutChartProps
    } = props;

    const theme = useTheme();
    const { i18n } = useTranslation();

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const headingsScore = formattedScore?.headingsScore ?? {};
    const scoreVariations = formattedScore?.scoreVariations ?? {};
    const scoreVariation = scoreVariations[displayedVariationId];

    const scoreVariationValue = scoreVariation?.score ?? 0;

    const scoreVariationValueStr = formatNumber(scoreVariationValue, i18n.resolvedLanguage, {
        maximumFractionDigits: precision,
    });
    const scoreVariationValueVariant = scoreVariationValueStr.length <= 7 ? "h1" : "h2";

    const scoreVariationFallbackUnit = typeof scoreVariation?.unit === "string" ? scoreVariation?.unit : "";
    const scoreVariationUnit = scoreVariation?.unit_abbreviation ?? scoreVariationFallbackUnit;

    const displayedScoresIds = Object.keys(headingsScore).filter((id: string) =>
        headings.find((heading: Heading) => Number(heading.id) === Number(id)),
    );

    const values = displayedScoresIds.map((key: string) => headingsScore[key].score ?? 0);

    const labels = displayedScoresIds.map(
        (key: string) => headings.find((heading: Heading) => Number(heading.id) === Number(key))?.title ?? "",
    );

    return (
        <DoughnutChart
            values={values}
            labels={labels}
            legend={{ position: "bottom" }}
            valuesFormatterFn={(value) =>
                formatNumber(
                    value,
                    i18n.resolvedLanguage,
                    precision !== undefined ? { maximumFractionDigits: precision } : {},
                )
            }
            {...doughnutChartProps}
        >
            <Stack>
                <Typography variant={scoreVariationValueVariant} fontWeight={800}>
                    {scoreVariationValueStr}
                </Typography>
                <Typography variant="body2" color={theme.custom.grey[1400]}>
                    {scoreVariationUnit}
                </Typography>
            </Stack>
        </DoughnutChart>
    );
};
