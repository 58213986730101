import { Box, Container, Typography, Divider, Stack } from "@mui/material";
import { Loading } from "src/components/utils/Loading";
import { useApplicationParameters } from "src/api/client-api/appParams";
import { Theme } from "./Fields/Theme";
import { Mode } from "./Fields/Mode";
import { BaseIconUpload } from "./BaseIconUpload";
import { BaseStringUpload } from "./BaseStringUpload";
import { RadioBooleanField } from "src/components/Radio/RadioBooleanField";
import { BaseToggleUpload } from "./BaseToggleUpload";

export const AppParamsSettings = () => {
    const { applicationParameters } = useApplicationParameters();

    if (applicationParameters === undefined) return <Loading />;

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Typography variant="h1">Paramètres</Typography>
            <Typography variant="big-bold">Please, validate your changes one field at a time.</Typography>
            <Box sx={{ py: 2 }} />
            <Divider />
            <Box sx={{ py: 2 }} />

            <Stack gap={"30px"}>
                <Theme currentTheme={applicationParameters.theme ?? null} />
                <Mode currentMode={applicationParameters.mode ?? null} />
                <Divider />
                <BaseStringUpload
                    label={"Title of the tab"}
                    description={"This appears on the very top of your browser"}
                    appParamKey={"tabTitle"}
                />
                <Divider />
                <Stack flexDirection={"row"} gap={"75px"}>
                    <BaseIconUpload
                        label={"Fav icon"}
                        description={"This represents the Icon of your tab, and in your favorites"}
                        appParamKey={"tabIcon"}
                    />
                    <BaseIconUpload
                        label={"Footer"}
                        description={"Image used to the far-left of the footer - Size is FIXED in the footer"}
                        appParamKey={"footerLogo"}
                    />
                    <BaseIconUpload
                        label={"PDF Front Cover Image"}
                        description={"A4-format image displayed on the front cover of the PDF"}
                        appParamKey={"front_cover_pdf"}
                    />
                </Stack>
                <Divider />
                <BaseStringUpload
                    label={"Freshdesk Widget ID"}
                    description={"ID of the Freshdesk window that appears on the screen"}
                    appParamKey="freshdeskWidgetId"
                />
                <Divider />
                <BaseToggleUpload
                    appParamKey={"FS-strategy"}
                    label={"Page stratégie"}
                    description={"Feature switch of Stratégie"}
                />
            </Stack>
            <Box sx={{ py: 2 }} />
        </Container>
    );
};
