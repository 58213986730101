import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { SectionTab, SectionTabs } from "@foodpilot/foods";
import ScoresIcon from "src/assets/icons/scores.svg?react";
import QuestionnaireIcon from "src/assets/icons/questionnaire.svg?react";
import InformationIcon from "src/assets/icons/information.svg?react";
import { Loading } from "src/components/utils/Loading";
import { useTranslation } from "react-i18next";
import { CampaignSelector } from "src/components/Selector";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";
import { useProductContext } from "./ProductContext";
import { useSectionResolverContext } from "../SectionResolver";

export type Sections = {
    section: "scores" | "questionnaire" | "informations";
};

type ProductDetailsSections = {
    [section in Sections["section"]]: SectionTab & {
        title?: string;
    };
};

export const ProductDetails = () => {
    const { section } = useSectionResolverContext();
    const theme = useTheme();
    const { selectedCompany } = useFoodpilotContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { scoreId } = useParams();

    const { product, isLoading } = useProductContext();

    if (isLoading || !section) return <Loading />;
    if (!product) return "Product not found";

    const sections: ProductDetailsSections = {
        scores: {
            name: t("Scores & indicateurs clés"),
            title: t("Indicateurs du produit"),
            icon: <ScoresIcon width="22px" />,
            onClick: () => {
                if (selectedCompany === null) return;
                const url = URLHandler.product.scores(selectedCompany.id, product.id);
                navigate(url);
            },
        },
        questionnaire: {
            name: t("Questionnaire"),
            title: t("Questionnaire du produit"),
            icon: <QuestionnaireIcon width="16px" />,
            onClick: () => {
                if (selectedCompany === null) return;

                const url = URLHandler.product.ssq(selectedCompany.id, product.id);
                navigate(url);
            },
        },
        informations: {
            name: t("Informations"),
            title: t("Informations du produit"),
            icon: <InformationIcon width="16px" />,
            onClick: () => {
                if (selectedCompany === null) return;

                const url = URLHandler.product.information(selectedCompany.id, product.id);
                navigate(url);
            },
        },
    };

    return (
        <Box component="div" sx={{ marginLeft: "auto", marginRight: "auto" }}>
            <Box
                sx={{
                    width: "auto",
                    background: "#fff",
                    padding: "28px 52px",
                    paddingBottom: 0,
                    position: "relative",
                    borderBottom: `1px solid ${theme.custom.grey[500]}`,
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="16px"
                    sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                    <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                        <ArrowBackIcon
                            style={{
                                cursor: "pointer",
                                margin: "8px",
                            }}
                            onClick={() => {
                                if (selectedCompany === null) return;
                                const url = URLHandler.product.list(selectedCompany.id);
                                navigate(url);
                            }}
                        />
                        <Typography variant="h1">{product.title}</Typography>
                    </Box>
                    <CampaignSelector />
                </Stack>

                <Divider sx={{ marginTop: "28px" }} />
                <SectionTabs
                    tabs={Object.values(sections)}
                    value={Object.keys(sections).indexOf(section)}
                    renderComponents={false}
                    sx={{
                        position: "relative",
                        bottom: "-1px",
                    }}
                />
            </Box>

            <Stack gap="28px" sx={{ padding: "22px 52px" }}>
                {scoreId === undefined && (
                    <Typography variant="h2">{sections[section].title ?? sections[section].name}</Typography>
                )}
                <Outlet />
            </Stack>
        </Box>
    );
};
