import { Article, FoodsTextInput, RichTextEditor, RoundInput } from "@foodpilot/foods";
import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SelectMedia } from "src/pages/Settings/CMS/Common/SelectMedia";
import { ContentBlockForm } from "../../_Common/BlockForm/ContentBlock/contentBlock";

type ArticleFormFieldsProps = {
    articleBlockForm: ContentBlockForm;
    setArticleBlockForm: (newArticle: ContentBlockForm) => void;
};
export const ArticleFormFields = (props: ArticleFormFieldsProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { articleBlockForm, setArticleBlockForm } = props;

    return (
        <Stack gap={theme.spacing(2)} padding={theme.spacing(2, 8)}>
            <Article
                title={articleBlockForm.title}
                description={articleBlockForm.description ?? ""}
                imageSrc={articleBlockForm.media?.url ?? ""}
                onClick={() => {}}
                isFirst={false}
            />
            <Stack gap={theme.spacing(1)}>
                <Typography variant="body-medium">{t("glossary.title")}</Typography>
                <FoodsTextInput
                    value={articleBlockForm.title}
                    onChange={(newValue) => {
                        setArticleBlockForm({
                            ...articleBlockForm,
                            title: newValue,
                        });
                    }}
                />
            </Stack>
            <Stack gap={theme.spacing(1)}>
                <Typography variant="body-medium">{t("glossary.description")}</Typography>
                <RichTextEditor
                    key={`rich-text-editor-article-form-${articleBlockForm.id}`}
                    initialContent={articleBlockForm.description ?? undefined}
                    onChange={(newValue) => {
                        setArticleBlockForm({
                            ...articleBlockForm,
                            description: newValue,
                        });
                    }}
                />
            </Stack>
            <SelectMedia
                selectedMediaIRI={articleBlockForm.media?.["@id"] ?? ""}
                selectNewMedia={(newMedia) => {
                    const newBlock = {
                        ...articleBlockForm,
                        media: newMedia,
                    };
                    setArticleBlockForm(newBlock);
                }}
            />
        </Stack>
    );
};
