import { ZodSchema } from "zod";
import { zodParseWithLog } from "src/api/apiErrorLogs.ts";

type RequestData = {
    "hydra:totalItems": number;
    "hydra:member": unknown;
};

export type SortType = {
    id: string;
    desc: boolean;
};

export type QuerySortType = Record<string, "asc" | "desc">;

export type ScoreEntityEndpointOptions = {
    campaign?: number;
    company_id?: number;
    variations?: Record<number, number>;
    dimensions?: string[][];
    enabled?: boolean;
};

export const parseAndSelect = <T>(
    request: { data: RequestData },
    schema: ZodSchema<T>,
    queryKey: string,
): { items: T; totalItems: number } => {
    const totalItems = request.data["hydra:totalItems"];
    const permissions = request.data["hydra:member"];

    const items = zodParseWithLog(permissions, schema, queryKey);

    return {
        items,
        totalItems,
    };
};

export const mrtSortingStateToObject = (sorting: Array<SortType>): QuerySortType => {
    return sorting.reduce<QuerySortType>((acc, { id, desc }) => {
        acc[id] = desc ? "desc" : "asc";

        return acc;
    }, {});
};

export const getDimensionsQueryParam = (dimensions: string[][]): string[] => {
    return dimensions.reduce((acc, path: string[]) => {
        acc.push(path.join(","));

        return acc;
    }, []);
};

export type EntityID = string | number | undefined;
