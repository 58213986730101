import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ScoreDimensionValue } from "src/api/client-api/post/scoreSchema.ts";
import { DistributionDetails } from "src/components/ScoreDetail/SecondaryTabs/DistributionDetails.tsx";
import { Box } from "@mui/material";
import { FoodsToggleButton } from "@foodpilot/foods";
import { URLHandler } from "src/services/URLHandler.ts";
import { useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { useVariableTranslation } from "src/utils/i18n.ts";
import { ResponsesModal } from "src/components/ScoreDetail/Responses/ResponsesModal.tsx";
import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext.tsx";
import { useCompanyContext } from "src/context/Company/useCompanyContext.ts";
import { useIsVariationRefetching } from "src/utils/variationRefetching.ts";

const additionalDimensions = "components";

type DistributionProps = {
    selectedDimensionId?: string;
    selectedValueIndex: string[];
    variations: Record<number, number>;
    isRefetching?: boolean;
};

export const dimensionOrder = ["headings", "lca"];
export const Distribution = (props: DistributionProps) => {
    const { selectedDimensionId, isRefetching = false, variations } = props;

    const trans = useVariableTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogTitle, setDialogTitle] = useState<string>("");
    const [propertyId, setPropertyId] = useState<number>();
    const [selectedScore, setSelectedScore] = useState<ScoreDimensionValue>();

    const {
        company,
        ssq: { post },
    } = useCompanyContext();

    const { selectedCompany, selectedCompanyVariations } = useFoodpilotContext();
    const {
        setSelectedScore: setScore,
        list,
        addSelectedValue,
        dimension,
        scorePrecision,
        getMainScore,
    } = useScoreDetailContext();

    const { scoreId: scoreIdString } = useParams<{ scoreId: string }>();
    const scoreId = Number(scoreIdString);

    const isVariationRefetching = useIsVariationRefetching(isRefetching, variations);

    if (!dimension) {
        return null;
    }

    const actions: ((() => void) | undefined)[] = Object.entries(list || {}).map(([key, value], index) => {
        if (
            Array.isArray(value.dimensions) &&
            value.dimensions.filter((dimension) => dimension.label !== "multivalues").length > 0
        ) {
            return () => {
                setScore(value);
                addSelectedValue(key, 0, value, index);
            };
        } else if (dimension.label === "properties") {
            return () => {
                setSelectedScore(value);
                setPropertyId(Number(key));
                setDialogTitle(dimension.values[key].label);
                setDialogOpen(true);
            };
        }
    });

    const mainDimensions = getMainScore()?.dimensions.filter((dimension) => dimension.label !== additionalDimensions);

    const sortedDimensions = (mainDimensions || []).sort(
        (a, b) => dimensionOrder.indexOf(a.label) - dimensionOrder.indexOf(b.label),
    );

    const glossaryButtons = sortedDimensions.map((dimension) => {
        const url = company ? URLHandler.company.score(company!.id, scoreId) : "";
        return {
            id: dimension.label,
            label: trans(`glossary.dimensions.${dimension.label}`, dimension.label),
            onClick: () => {
                navigate(`${url}?dimensions=${dimension.label}`);
            },
        };
    });

    const urlRequestedDimension = new URLSearchParams(searchParams).get("dimensions");
    const findDefaultGlossaryButtonIndex = (): number => {
        if (!urlRequestedDimension) {
            return 0;
        }

        const selected = glossaryButtons.findIndex((button) => button.id === urlRequestedDimension);
        return selected === -1 ? 0 : selected;
    };

    const values = Object.values(list || {});

    return (
        <Box>
            {glossaryButtons.length > 1 && (
                <Box display="flex" sx={{ mb: "24px", "& > div": { display: "flex", gap: "16px" } }}>
                    <FoodsToggleButton
                        buttons={glossaryButtons}
                        initialSelected={glossaryButtons[findDefaultGlossaryButtonIndex()]}
                    />
                </Box>
            )}
            <DistributionDetails
                referenceScoreValue={undefined}
                isVariationRefetching={isVariationRefetching}
                prevCampaignName={selectedCompany?.prevCampaignName}
                labels={values.map((value) => value.label || "-- no label --")}
                chartValues={values.map((value) => Number((value.score ?? 0).toFixed(scorePrecision)))}
                actions={actions}
                variations={selectedCompanyVariations}
            />
            {dialogOpen && propertyId && (
                <ResponsesModal
                    posts={post ? [post] : []}
                    title={dialogTitle}
                    open={dialogOpen}
                    propertyId={propertyId}
                    score={selectedScore}
                    setOpen={setDialogOpen}
                    onModifyAnswersClick={() => {
                        if (!selectedCompany || !company || !selectedDimensionId) {
                            return;
                        }

                        const url = URLHandler.company.ssqHeading(company?.id, Number(selectedDimensionId));
                        navigate(url);
                    }}
                />
            )}
        </Box>
    );
};
