import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ComparisonBlock, NumberWithUnit } from "@foodpilot/foods";
import ProgressBar from "src/components/ProgressBar.tsx";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils/formatting.ts";
import { createDistribution } from "src/utils/calculation.ts";
import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext.tsx";

type DistributionImpactsProps = {
    referenceScoreValue?: number;
    isVariationRefetching: boolean;
    prevCampaignName?: string;
    labels: string[];
    values: number[];
    actions?: ((() => void) | undefined)[];
    variations?: Record<number, number>;
};

export const DistributionImpactsDetails = (props: DistributionImpactsProps) => {
    const { labels, values, prevCampaignName, isVariationRefetching, referenceScoreValue, variations = {} } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const distribution = createDistribution(values);
    const { getScoreValue, getPrevScoreValue, getUnit, scoreId } = useScoreDetailContext();

    /**
     * Accepts an array of descending sorted values and returns an array of percentages
     * @param values
     */
    const createImpactDistribution = (values: number[]) => {
        if (values.length === 0) {
            return [];
        }

        const determinant = values[0];

        if (determinant === 0) {
            return Array(values.length).fill(0);
        }

        return values.map((value, index) => {
            if (index === 0) {
                return 100;
            }

            if (value > 0) {
                return Math.round((value / determinant) * 100);
            }

            return 0;
        });
    };

    const sortLinkedData = (percentagesInput: number[], factsInput: string[]) => {
        const indexedArr = percentagesInput.map((value, index) => ({ value, index }));

        indexedArr.sort((a, b) => b.value - a.value);

        const percentages = indexedArr.map((item) => item.value);
        const facts = indexedArr.map((item) => factsInput[item.index]);

        return { percentages, facts };
    };

    const { percentages, facts } = sortLinkedData(distribution, labels);
    const impactDistribution = createImpactDistribution(percentages);

    const scoreValue = getScoreValue();
    const prevScoreValue = getPrevScoreValue();
    const unit = getUnit();

    const isPrevScore =
        prevScoreValue !== undefined &&
        prevScoreValue !== null &&
        !isVariationRefetching &&
        prevCampaignName !== undefined;

    const displayedVariationId = variations[scoreId];
    const isReferenceScore =
        referenceScoreValue !== null &&
        referenceScoreValue !== undefined &&
        !isVariationRefetching &&
        !displayedVariationId;

    return (
        <Stack direction="row" gap="24px">
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    width: "100%",
                    border: `1px solid ${theme.palette.grey[500]}`,
                    borderRadius: "12px",
                    maxWidth: "230px",
                    padding: "1.5rem",
                    alignItems: "center",
                    height: "fit-content",
                }}
            >
                <Box mb={2}>
                    <NumberWithUnit value={scoreValue} unit={unit || ""} size="big" />
                </Box>
                {(isPrevScore || isReferenceScore) && (
                    <Stack
                        sx={{
                            width: "170px",
                            minWidth: "170px",
                            "& .comparison-block-wrapper": {
                                width: "100%",
                            },
                        }}
                        direction="column"
                        gap="24px"
                    >
                        <Box>
                            {isPrevScore && (
                                <ComparisonBlock
                                    currentValue={scoreValue}
                                    comparedTo={{
                                        value: prevScoreValue,
                                        unit: unit,
                                        label: prevCampaignName,
                                    }}
                                    improvement="increase"
                                    layout={{
                                        direction: "vertical",
                                        size: "L",
                                    }}
                                />
                            )}
                        </Box>
                        {isReferenceScore && (
                            <ComparisonBlock
                                currentValue={scoreValue}
                                comparedTo={{
                                    value: referenceScoreValue,
                                    unit: unit,
                                    label: t("score_reference.the_reference"),
                                }}
                                improvement="increase"
                                layout={{
                                    direction: "vertical",
                                    size: "L",
                                }}
                            />
                        )}
                    </Stack>
                )}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    width: "100%",
                    border: `1px solid ${theme.palette.grey[500]}`,
                    borderRadius: "12px",
                    justifyContent: "center",
                }}
            >
                {facts.map((label, index) => {
                    return (
                        <Stack
                            sx={{
                                width: "100%",
                                padding: "8px 16px",
                                borderBottom: `1px solid ${theme.palette.grey[500]}`,
                                "&:last-of-type": {
                                    borderBottom: "none",
                                },
                            }}
                            key={index}
                            direction="row"
                        >
                            <Box sx={{ width: "45%", display: "flex", alignItems: "center" }}>
                                <Typography variant="h5" component="h5">
                                    {label}
                                </Typography>
                            </Box>
                            <Box sx={{ width: "55%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Box
                                    alignItems="center"
                                    sx={{
                                        display: "flex",
                                        borderRadius: "4px",
                                        background: theme.custom.grey[400],
                                        padding: "0.1rem 1.1rem",
                                        fontWeight: 500,
                                        fontSize: "0.75rem",
                                        width: 60,
                                        justifyContent: "center",
                                    }}
                                >
                                    {formatNumber(percentages[index])}%
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                    <ProgressBar
                                        sx={{
                                            pl: "1.5rem",
                                            pr: "0.5rem",
                                        }}
                                        progress={impactDistribution[index]}
                                        barColor={theme.palette.primary[500]}
                                    />
                                </Box>
                                {/*<Box sx={{ minWidth: 40 }}>*/}
                                {/*    <Tooltip title="Content" placement="top">*/}
                                {/*        <IconButton>*/}
                                {/*            <FoodsIcon icon="information" size={2} />*/}
                                {/*        </IconButton>*/}
                                {/*    </Tooltip>*/}
                                {/*</Box>*/}
                            </Box>
                        </Stack>
                    );
                })}
            </Box>
        </Stack>
    );
};
