import { FoodsIcon, LinearProgress } from "@foodpilot/foods";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useProductContext } from "../ProductContext";
import { IngredientCompletionService } from "./IngredientCompletionService";

export const IngredientsHeader = () => {
    const theme = useTheme();
    const { product, ingredientPosts } = useProductContext();
    const { totalIngredientCompletion = 0 } = IngredientCompletionService.calculateTotalCompletionRates(
        product?.productIngredients ?? [],
        ingredientPosts,
    );

    return (
        <Stack direction={"row"} gap={theme.spacing(2.5)} width={"100%"} alignItems="center">
            <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                    backgroundColor: (theme) => theme.custom.grey[400],
                    borderRadius: "28px",
                    padding: theme.spacing(1),
                }}
                width={"44px"}
                height={"44px"}
            >
                <FoodsIcon icon="ingredients" size={3.5} />
            </Stack>
            <Stack>
                <Stack direction={"row"} alignItems={"baseline"} gap={theme.spacing(0.75)}>
                    <Typography variant="h3">{t("Ingredients")}</Typography>
                    <Typography variant="huge">{`- ${product?.productIngredients?.length ?? 0}`}</Typography>
                </Stack>
                <Stack>
                    {!!totalIngredientCompletion && (
                        <Box display="flex" alignItems="center" gap={theme.spacing(1.5)}>
                            <Typography variant="body-medium">{t("Complétion totale")} :</Typography>
                            <Box width="200px">
                                <LinearProgress value={totalIngredientCompletion} />
                            </Box>
                        </Box>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};
