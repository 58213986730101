import { FoodsTextInput } from "@foodpilot/foods";
import { Box } from "@mui/material";
import { ProductFormProductIngredient } from "src/pages/Product/ProductForm/ProductForm.types";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { TextLink } from "src/components/Link/TextLink";
import { URLHandler } from "src/services/URLHandler";

type LineTitleProps = {
    ingredient: ProductFormProductIngredient;
    setIngredient: (newIngredient: ProductFormProductIngredient) => void;
};
export const LineTitle = (props: LineTitleProps) => {
    const { selectedCompany } = useFoodpilotContext();
    const { ingredient, setIngredient } = props;

    if (selectedCompany === null) return;

    // Concerning the `FoodsTextInput` below, I have put the `maxWidth` in order to
    // circumvent other displaying problems that were not properly adressed from before.
    // I'm thinking we'll want to make more generic way of having Tables :)
    return (
        <Box
            sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 200px 125px",
            }}
        >
            <Box sx={{ gridColumn: "1" }}>
                <TextLink
                    url={URLHandler.ingredient.scores(selectedCompany.id, ingredient.ingredient.id)}
                    text={ingredient.ingredient.name}
                />
            </Box>
            <Box sx={{ gridColumn: "2", maxWidth: "150px" }}>
                <FoodsTextInput
                    value={ingredient.usedQuantity?.toString() ?? ""}
                    onChange={(newValue) => {
                        const newIngredient: ProductFormProductIngredient = {
                            ...ingredient,
                            usedQuantity: newValue === "" ? null : Number(newValue),
                        };
                        setIngredient(newIngredient);
                    }}
                    unit={"g"}
                    ExtraInputProps={{
                        type: "number",
                        placeholder: "0",
                    }}
                />
            </Box>
            <Box sx={{ gridColumn: "3", maxWidth: "150px" }}>
                <FoodsTextInput
                    value={ingredient.quantity?.toString() ?? ""}
                    onChange={(newValue) => {
                        const newIngredient: ProductFormProductIngredient = {
                            ...ingredient,
                            quantity: newValue === "" ? null : Number(newValue),
                        };
                        setIngredient(newIngredient);
                    }}
                    unit={"g"}
                    ExtraInputProps={{
                        type: "number",
                        placeholder: "0",
                    }}
                />
            </Box>
        </Box>
    );
};
