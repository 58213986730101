import { t } from "i18next";
import { useProductContext } from "../ProductContext";
import { SummaryDataRow } from "./SummaryData";
import { ScoreDimensionValue } from "src/api/client-api/post/scoreSchema";
import { IngredientCompletionService } from "../Ingredients/IngredientCompletionService";

export const useBuildSummaryData = (dimensionScore?: Record<string, ScoreDimensionValue>[] | undefined) => {
    const { product, ssq, post, ingredientPosts } = useProductContext();
    const { totalCompletionRate } = IngredientCompletionService.calculateTotalCompletionRates(
        product?.productIngredients ?? [],
        ingredientPosts,
    );
    const ingredientCount = (product?.productIngredients ?? []).length;
    const totalCompletion = ssq && post ? post.completions.ssq.result : 0;
    const totalIngredientCompletion = ingredientCount ? totalCompletionRate / ingredientCount : 0;

    const summaryData: SummaryDataRow[] = [
        {
            percentComplete: totalCompletion,
            count: dimensionScore?.length ?? 0,
            icon: "questionnaires",
            title: t("Products"),
            to: "../../questionnaire",
        },
        {
            percentComplete: totalIngredientCompletion,
            count: ingredientCount,
            icon: "ingredients",
            title: t("Ingredients"),
            to: "../ingredients",
        },
    ];

    return summaryData;
};
