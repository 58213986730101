import { Box, Typography } from "@mui/material";
import { Answer, GetValueType } from "src/components/ScoreDetail/Responses/ResponsesModal.tsx";

type TextProps = {
    list: Answer[];
} & GetValueType;

export const Text = (props: TextProps) => {
    const { list, getValue } = props;

    return list.map((item, key) => {
        return (
            <Box key={key}>
                <Typography
                    variant="body"
                    component="span"
                    fontWeight={600}
                    fontSize="20px"
                    mr="8px"
                    sx={{
                        wordBreak: "break-word",
                    }}
                >
                    {getValue(item)}
                </Typography>
                {item.unitAbbreviation && (
                    <Typography
                        variant="body"
                        component="span"
                        sx={(theme) => ({
                            color: theme.custom.grey[1400],
                            fontSize: "12px",
                            fontWeight: 500,
                        })}
                    >
                        {item.unitAbbreviation}
                    </Typography>
                )}
            </Box>
        );
    });
};
