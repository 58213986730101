import { allMainColors, DoughnutChart, ScoreCard, ScoreCardProps } from "@foodpilot/foods";
import { useState } from "react";
import { ScoreVariation } from "src/context/EntityBasedScore/ScoreService";

export type ScoreChartValues = {
    values: number[];
    labels: string[];
};

export interface ScoreChartProps {
    precision?: number;
    data?: ScoreChartValues;
    totalScore?: number;
    unit: string;
    allScoreWithVariations: ScoreVariation[];
    isRefetching?: boolean;
    isLoading?: boolean;
    scoreState?: {
        lastSelectedVariation?: {
            scoreVariationId?: number;
            scoreId?: number;
        };
    };
}

export const ScoreChart = ({
    unit,
    precision = 2,
    data,
    totalScore,
    allScoreWithVariations,
    isRefetching,
    isLoading,
}: ScoreChartProps) => {
    const selectedVariationId = allScoreWithVariations[0]?.id;
    const validPrecision = Math.min(Math.max(precision, 1), 100);

    const [activeScoreId, setActiveScoreId] = useState<number | null>(selectedVariationId ?? null);

    const variationOptions = allScoreWithVariations.map((variation) => ({
        ...variation,
        onClick: () => {
            variation.onClick();
            setActiveScoreId(variation.id);
        },
    }));

    const scoreCardHeader: ScoreCardProps["header"] = {
        selectedItemId: activeScoreId,
        options: variationOptions,
        variant: "secondary",
    };

    const buildChartValues = () => {
        if (!data) return { values: [], labels: [] };

        const validScoreEntries = data.values.flatMap((value, index) =>
            !isNaN(value) ?
                [
                    {
                        value: Number(value.toPrecision(validPrecision)),
                        label: data.labels[index],
                    },
                ]
            :   [],
        );

        return validScoreEntries.reduce<ScoreChartValues>(
            (scores, scoreElement) => ({
                values: [...scores.values, scoreElement.value],
                labels: [...scores.labels, scoreElement.label],
            }),
            { values: [], labels: [] },
        );
    };

    const { values, labels } = buildChartValues();
    const chartColors = Object.values(allMainColors).slice(0, values.length);

    const isLoadingData = isLoading || isRefetching;

    return (
        <ScoreCard header={scoreCardHeader} disableFooter>
            <DoughnutChart
                values={values}
                labels={labels}
                colors={chartColors}
                isLoading={isLoadingData}
                displayTotal={`${Number(totalScore?.toFixed(validPrecision))}`}
                valuesUnit={unit}
                legend={{ position: "bottom" }}
            />
        </ScoreCard>
    );
};
