import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, useTheme, CircularProgress, Box } from "@mui/material";
import { FormPropertyProps } from "../FormProperty";
import { formatUnitAbbreviation } from "src/utils/formatting";
import { useScoreContext } from "src/context/ScoreContext";
import { getFormattedScoreByScoreId } from "src/utils/ssq";
import { GenericValueCheckbox } from "src/components/FormProperty/GenericValueCheckbox.tsx";
import { SSQTextInput } from "src/components/SSQ/FormFields/SSQTextInput";

export const Text = (props: FormPropertyProps) => {
    const { t } = useTranslation();
    const { displayedScoreId, displayedVariationId, formattedScores } = useScoreContext();
    const { property, post, element, canEdit = true, isSubproperty, isMultivalued } = props;

    const _defaultValue = property.defaultValue ?? "";
    const initialValue = element?.value ?? "";

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const propertyScore = formattedScore?.properties?.[property.id];

    const scoreVariations = formattedScore?.scoreVariations ?? {};
    const scoreVariation = scoreVariations?.[displayedVariationId ?? 0];

    const propertyVariations = propertyScore?.variations ?? {};
    const propertyVariation = propertyVariations?.[displayedVariationId ?? 0];

    const [previousValue, setPreviousValue] = useState(initialValue);
    const [value, setValue] = useState(initialValue);
    const [genericValueSelected, setGenericValueSelected] = useState<boolean>(element?.isGenericValue ?? false);

    const genericValue = property.genericValue;

    if (!post) return <CircularProgress />;

    const unit = formatUnitAbbreviation(property.unit);

    const updatePropertyValue = (value?: string, isGenericValue?: boolean) => {
        const sanitizedValue = value?.trim() ?? "";

        props.onUpdate({
            postId: post.id,
            propertyId: Number(property.id),
            value: sanitizedValue,
            position: Number(props.position ?? 0),
            isGenericValue: isGenericValue,
        });
    };

    const defaultValueText = property.readonly ? t("Cette valeur est en lecture seule") : t("Valeur par défaut :");

    const tooltipText = property.hint ? <div dangerouslySetInnerHTML={{ __html: property.hint }} /> : undefined;
    const isDefaultActivated = !property.readonly && _defaultValue && !genericValueSelected;
    const defaultValue = isDefaultActivated ? _defaultValue : undefined;

    const shouldDisplayScore = !!propertyScore?.score && isSubproperty && !isMultivalued;
    const scoreProperty =
        shouldDisplayScore === false ? null : (
            {
                score: Number((propertyVariation?.score ?? propertyScore?.score).toFixed(3)),
                unit: scoreVariation?.unit_abbreviation ?? scoreVariation?.unit,
            }
        );

    const onCalculate = (newValue: string) => {
        if (newValue === previousValue) return;
        updatePropertyValue(newValue, undefined);
    };

    return (
        <Stack direction="column" gap="8px">
            <Stack direction="row" alignItems="center" gap="12px">
                <SSQTextInput
                    onCalculate={onCalculate}
                    score={scoreProperty}
                    tooltipText={tooltipText}
                    defaultOption={
                        defaultValue === undefined ? undefined : (
                            {
                                value: defaultValue,
                                label: defaultValueText,
                            }
                        )
                    }
                    value={value !== "_empty_" ? value : ""}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    unit={unit}
                    disabled={property.readonly || !canEdit || genericValueSelected}
                    ExtraInputProps={{
                        onFocus: (e) => {
                            const isFocusDirect = !e.relatedTarget;

                            if (!isFocusDirect) {
                                return;
                            }

                            setPreviousValue(value);
                        },
                    }}
                />
            </Stack>
            {canEdit && !property.readonly && genericValue && (
                <Box>
                    <GenericValueCheckbox
                        isSelected={genericValueSelected}
                        onChange={() => {
                            setGenericValueSelected((prevState) => !prevState);
                            setValue(!genericValueSelected ? genericValue : "");
                            updatePropertyValue(!genericValueSelected ? genericValue : "", !genericValueSelected);
                        }}
                    />
                </Box>
            )}
        </Stack>
    );
};
