import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography, useTheme, CircularProgress, Box } from "@mui/material";
import { FormPropertyProps } from "../FormProperty";
import { formatNumber, formatUnitAbbreviation } from "src/utils/formatting";
import { getFormattedScoreByScoreId } from "src/utils/ssq";
import { useScoreContext } from "src/context/ScoreContext";
import { GenericValueCheckbox } from "src/components/FormProperty/GenericValueCheckbox.tsx";
import { SSQTextInput } from "src/components/SSQ/FormFields/SSQTextInput";

export const Numeric = (props: FormPropertyProps) => {
    const { t, i18n } = useTranslation();
    const { displayedScoreId, displayedVariationId, formattedScores } = useScoreContext();

    const theme = useTheme();
    const { property, post, element, canEdit = true, isSubproperty, isMultivalued } = props;

    const _defaultValue = (property.defaultValue ?? "").replace(/\s/g, "").replace(/,/g, ".");
    const formattedDefaultValue = formatNumber(_defaultValue, i18n.resolvedLanguage, { maximumFractionDigits: 20 });
    const initialValue = (element?.value ?? "").replace(/\s/g, "").replace(/,/g, ".");

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const propertyScore = formattedScore?.properties?.[property.id];

    const scoreVariations = formattedScore?.scoreVariations ?? {};
    const scoreVariation = scoreVariations?.[displayedVariationId ?? 0];

    const propertyVariations = propertyScore?.variations ?? {};
    const propertyVariation = propertyVariations?.[displayedVariationId ?? 0];

    const [previousValue, setPreviousValue] = useState(initialValue);
    const [value, setValue] = useState(initialValue);
    const [genericValueSelected, setGenericValueSelected] = useState<boolean>(element?.isGenericValue ?? false);

    const genericValue = property.genericValue;

    const [error, setError] = useState(false);

    if (!post) return <CircularProgress />;

    const unit = formatUnitAbbreviation(property.unit);

    useEffect(() => {
        if (value === "") {
            return setError(false);
        }
        const v = Number(value);

        const isBelowMin = property.minValue ? v < property.minValue : false;
        const isAboveMax = property.maxValue ? v > property.maxValue : false;
        setError(isBelowMin || isAboveMax);
    }, [value]);

    const updatePropertyValue = (value?: string, isGenericValue?: boolean) => {
        if (error) {
            return setValue(previousValue);
        }

        props.onUpdate({
            postId: post.id,
            propertyId: Number(property.id),
            value: value ?? "",
            position: Number(props.position ?? 0),
            isGenericValue: isGenericValue,
        });
    };

    const isValidNumber = (value: unknown) => {
        return typeof value === "number" && !isNaN(value);
    };

    const defaultValueText = property.readonly ? t("Cette valeur est en lecture seule") : t("Valeur par défaut :");

    const tooltipText = property.hint ? <div dangerouslySetInnerHTML={{ __html: property.hint }} /> : undefined;
    const isDefaultActivated = !property.readonly && _defaultValue && !genericValueSelected;
    const defaultValue = isDefaultActivated ? formattedDefaultValue : undefined;

    const shouldDisplayScore = !!propertyScore?.score && isSubproperty && !isMultivalued;
    const scoreProperty =
        shouldDisplayScore === false ? null : (
            {
                score: Number((propertyVariation?.score ?? propertyScore?.score).toFixed(3)),
                unit: scoreVariation?.unit_abbreviation ?? scoreVariation?.unit,
            }
        );

    const onCalculate = (newValue: string) => {
        if (newValue === previousValue) return;
        updatePropertyValue(newValue, undefined);
    };

    return (
        <Stack direction="column" gap="8px">
            <Stack direction="row" alignItems="center" gap="12px">
                <SSQTextInput
                    onCalculate={onCalculate}
                    score={scoreProperty}
                    tooltipText={tooltipText}
                    defaultOption={
                        defaultValue === undefined ? undefined : (
                            {
                                value: defaultValue,
                                label: defaultValueText,
                            }
                        )
                    }
                    value={value !== "_empty_" ? value : ""}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    unit={unit}
                    disabled={property.readonly || !canEdit || genericValueSelected}
                    ExtraInputProps={{
                        type: "number",
                        onFocus: (e) => {
                            const isFocusDirect = !e.relatedTarget;

                            if (!isFocusDirect) {
                                return;
                            }

                            setPreviousValue(value);
                        },
                    }}
                />
            </Stack>
            {error && (
                <Typography variant="caption" color={theme.custom.red[600]}>
                    {(() => {
                        if (isValidNumber(property.minValue) && isValidNumber(property.maxValue)) {
                            return t("form.min_max.error", { min: property.minValue, max: property.maxValue });
                        }
                        if (isValidNumber(property.minValue)) {
                            return t("form.min.error", { min: property.minValue });
                        }
                        if (isValidNumber(property.maxValue)) {
                            return t("form.max.error", { max: property.maxValue });
                        }
                        return null;
                    })()}
                </Typography>
            )}
            {canEdit && !property.readonly && genericValue && (
                <Box>
                    <GenericValueCheckbox
                        isSelected={genericValueSelected}
                        onChange={() => {
                            setGenericValueSelected((prevState) => !prevState);
                            setValue(!genericValueSelected ? genericValue : "");
                            updatePropertyValue(
                                !genericValueSelected ? genericValue : undefined,
                                !genericValueSelected,
                            );
                        }}
                    />
                </Box>
            )}
        </Stack>
    );
};
