import {
    MRT_Table,
    MRT_TableInstance,
    MRT_TableOptions,
    MRT_RowData,
    useMaterialReactTable,
} from "material-react-table";
import { useTheme } from "@mui/material";

export type SectionGridProps<T extends MRT_RowData> = MRT_TableOptions<T> & {
    mode?: "table" | "list" | "extended";
};

export function SectionGrid<T extends MRT_RowData>(props: SectionGridProps<T>): JSX.Element {
    const theme = useTheme();
    const isExtended = props.mode === "extended";

    let config: MRT_TableOptions<T> = {
        enableDensityToggle: false,
        enableRowActions: true,
        enableColumnActions: false,
        enableSorting: false,
        positionActionsColumn: "last",
        muiTableProps: {
            sx: {
                border: `1px solid ${theme.custom.grey[500]}`,
                borderRadius: theme.spacing(1),
                overflow: "hidden",
            },
        },
        muiTableHeadRowProps: {
            sx: {
                boxShadow: "none",
            },
        },
        muiTableHeadCellProps: {
            sx: {
                background: theme.custom.grey[200],
                color: theme.custom.grey[2000],
                fontSize: "12px",
                fontWeight: 500,
                padding: `${theme.spacing(1.25)} ${theme.spacing(4)}`,
                align: "center",
                verticalAlign: "middle",
                "& > div": {
                    justifyContent: "flex-start",
                },
            },
        },
        muiTableBodyRowProps: {
            sx: {
                "&:last-child:not(:hover) > td:after": {
                    display: "none",
                },
                "&:last-child > td:after": {
                    display: isExtended ? "none" : "inherit",
                },
                "&:first-of-type > td": {
                    paddingTop: isExtended ? "0" : theme.spacing(4),
                },
            },
            hover: !isExtended,
        },
        muiTableBodyCellProps: (value) => {
            return {
                sx: {
                    "&:first-of-type": {
                        paddingLeft: isExtended ? "0px" : theme.spacing(4),
                    },
                    "&:last-child": {
                        paddingRight: isExtended ? "0px" : theme.spacing(4),
                        width: "1%",
                    },
                    borderBottom: "none",
                    padding: theme.spacing(4),
                    ":after": {
                        content: '""',
                        height: "1px",
                        background: "#E7E5E4",
                        position: "absolute",
                        left:
                            isExtended ? 0
                            : value.column.getIsFirstColumn() ? theme.spacing(4)
                            : 0,
                        right:
                            isExtended ? 0
                            : value.column.getIsLastColumn() ? theme.spacing(4)
                            : 0,
                        bottom: 0,
                    },
                },
            };
        },
        localization: {
            actions: "",
        },
        ...props,
    };

    if (props.mode === "list") {
        config = {
            ...config,
            muiTableProps: {
                sx: {
                    overflow: "hidden",
                },
            },
            muiTableHeadRowProps: {
                sx: {
                    display: "none",
                },
            },
            muiTableBodyCellProps: () => {
                return {
                    sx: {
                        padding: theme.spacing(4),
                        ":last-child": {
                            width: "1%",
                        },
                    },
                };
            },
        };
    }

    const table: MRT_TableInstance<T> = useMaterialReactTable(config);

    return <MRT_Table table={table} />;
}
