import z from "zod";
import { useQuery } from "react-query";

import { publicApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";

const featureSwitchKeys = ["FS-homepage", "FS-strategy"] as const;

export const applicationParameterKeys = [
    ...featureSwitchKeys,
    "footerLogo",
    "freshdeskWidgetId",
    "front_cover_pdf",
    "mode",
    "tabIcon",
    "tabTitle",
    "theme",
] as const;
export type ApplicationParameterKeys = (typeof applicationParameterKeys)[number];

export const isAppParamKey = (key: string): key is ApplicationParameterKeys => {
    return applicationParameterKeys.includes(key as ApplicationParameterKeys);
};

// It is string | undefined because we can't promise the user that the key is defined in the backend.
type AppParamsRecord = Record<ApplicationParameterKeys, string | undefined>;

export const appParamKey = "applicationParameterKey" as const;

export const useApplicationParameters = () => {
    const query = useQuery({
        cacheTime: 0,
        staleTime: Infinity,
        queryKey: "ApplicationParameters",
        queryFn: async () => {
            return await publicApiCall.get(`/api/application_parameters`);
        },
        select: (request) => {
            const appParams = request.data["hydra:member"];
            const data = zodParseWithLog(appParams, ApplicationParametersZodSchema.array(), "applicationParams");

            const defaultParams: AppParamsRecord = applicationParameterKeys.reduce((acc, item) => {
                // This put to `undefined` every key defined in applicationParameterKeys above;
                acc[item] = undefined;
                return acc;
            }, {} as AppParamsRecord);

            const appParamRecord = data.reduce<AppParamsRecord>((acc, item) => {
                if (isAppParamKey(item.key)) {
                    // If key in AppParam isn't declared in front, it's just ignored
                    acc[item.key] = item.value;
                }
                return acc;
            }, defaultParams);

            return appParamRecord;
        },
    });

    return {
        applicationParameters: query.data,
        ...query,
    };
};

export type ApplicationParameter = {
    "@id": IRI;
    key: string;
    value: string;
};

export const ApplicationParametersZodSchema: z.ZodSchema<ApplicationParameter> = z.object({
    "@id": z.string(),
    key: z.string(),
    value: z.string(),
});
