import { createContext } from "react";
import {
    DeprecatedEmptyScore,
    Score,
    ScoreDimension,
    ScoreDimensionValue,
} from "src/api/client-api/post/scoreSchema.ts";
import { Ssq } from "src/api/sure-api/ssq/useGetOneSsq.ts";

type ScoreDetailContextInitialValues = {
    scoreId: number;
    dimension: ScoreDimension | null;
    depthLevel: number;
    selectedChartItemIndex?: number;
    setSelectedChartItemIndex: (index: number | undefined) => void;
    urlRequestedDimension: string | null;
    list: Record<string, ScoreDimensionValue> | null;
    selectedValueIndex: string[];
    setSelectedValueIndex: (value: string[]) => void;
    selectedDimensionIndex: number[];
    setSelectedDimensionIndex: (values: number[]) => void;
    selectedDimensionId: number | string | undefined;
    addSelectedValue: (valueIndex: string, dimensionIndex: number, score: ScoreDimensionValue, itemIdx: number) => void;
    prevScore: ScoreDimensionValue | null;
    scorePrecision?: number;
    selectedDimension?: string;
    setSelectedDimension: (dimension: string) => void;
    selectedScore?: ScoreDimensionValue;
    setSelectedScore: (score?: ScoreDimensionValue) => void;
    getMainScore: () => Score | DeprecatedEmptyScore | undefined | null;
    getScoreValue: () => number;
    getPrevScoreValue: () => number;
    getUnit: () => string | undefined;
    ssq?: Ssq;
    additionalScore: ScoreDimensionValue | null;
    reset: () => void;
};

export const ScoreDetailContext = createContext<ScoreDetailContextInitialValues | null>(null);
