import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Post, PostElement } from "src/api/client-api/post/postSchema";
import { Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { FormAccordion, ContextualContentBox } from "@foodpilot/foods";
import { PropertyContainer } from "./PropertyContainer";
import { filterHeadingsByHiddenElements, getScoreVariationValue, getVisibleProperties } from "src/utils/ssq";
import { useUpdateProperty, UpdateProperty, PostElementWrite } from "src/api/client-api/property/useUpdateProperty";
import { useDeleteProperty, DeleteProperty } from "src/api/client-api/property/useDeleteProperty";
import { getFormattedScoreByScoreId } from "src/utils/ssq";
import { Property } from "src/api/sure-api/ssq/useGetOneSsq";
import { useFormBaseContext } from "@foodpilot/foods";
import { useScoreContext } from "../../context/ScoreContext";
import { useSnackbar } from "src/utils/useSnackbar";
import { useTranslation } from "react-i18next";
import { isAxiosError } from "axios";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Score } from "src/types";

type PagesContentProps = {
    heading: Heading;
    post?: Post;
    canEdit: boolean;
    changePost: (callable: (post: Post) => void) => void;
};
export const PagesContent = (props: PagesContentProps) => {
    const { heading, post: initialPost, canEdit, changePost } = props;
    const { displayedScoreId, displayedVariationId, formattedScores, setFormattedScores } = useScoreContext();
    const { setSaving } = useFormBaseContext();
    const { scores } = useFoodpilotContext();

    const [post, setPost] = useState<Post | undefined>(undefined);

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const headingsScore = formattedScore?.headingsScore;
    const scoreVariations = formattedScore?.scoreVariations ?? {};
    const scoreVariation = scoreVariations?.[displayedVariationId ?? 0];

    const score = scores?.find((score: Score) => score.id === displayedScoreId);
    const defaultPrecision = 3;

    const snackbar = useSnackbar();
    const { t } = useTranslation();
    const updateProperty = useUpdateProperty();
    const deleteProperty = useDeleteProperty();

    const properties = post ? getVisibleProperties(heading, post.hiddenElements) : [];
    const visibleSubheadings = post ? filterHeadingsByHiddenElements(heading.children, post.hiddenElements) : [];

    const onPropertyUpdate = (data: UpdateProperty) => {
        setSaving(true);
        snackbar.forceClose();

        updateProperty.mutate(data, {
            onSuccess: (response) => {
                setFormattedScores(response.data);
                changePost((post: Post): void => {
                    setPost(post);
                });
            },
            onError: async (error) => {
                if (isAxiosError(error)) {
                    const isForbidden = error.response?.status === 401;
                    if (isForbidden === false) {
                        snackbar.setSnackbarProps({
                            message: t("ssq.form.error"),
                            type: "error",
                        });
                    }
                }
            },
            onSettled: () => {
                setSaving(false);
            },
        });

        if (!post) return;

        const element = post.elements?.find((element: PostElement | PostElementWrite) => {
            return data.propertyId === element.ssqPropertyId && element.position === (data.position ?? 0);
        });

        if (!element) {
            post.elements?.push({
                id: 0,
                ssqPropertyId: data.propertyId,
                value: String(data.value),
                position: data.position ?? 0,
            });
        } else {
            element.value = String(data.value);
        }

        setPost(post);
    };

    const onPropertyDelete = (data: DeleteProperty) => {
        setSaving(true);
        snackbar.forceClose();

        deleteProperty.mutate(data, {
            onSuccess: (response) => {
                setFormattedScores(response.data);
            },
            onError: async (error) => {
                if (isAxiosError(error)) {
                    const isForbidden = error.response?.status === 401;
                    if (isForbidden === false) {
                        snackbar.setSnackbarProps({
                            message: t("ssq.form.error"),
                            type: "error",
                        });
                    }
                }
            },
            onSettled: () => {
                setSaving(false);
            },
        });

        if (!post) return;

        post.elements = post.elements?.filter((element: PostElement | PostElementWrite) => {
            return !(data.propertyId === element.ssqPropertyId && element.position === (data.position ?? 0));
        });

        setPost(post);
    };

    useEffect(() => {
        setPost(initialPost);
    }, [initialPost]);

    return (
        <Stack gap="28px" alignItems="stretch">
            {heading.helperText && (
                <ContextualContentBox size="small">
                    <Box dangerouslySetInnerHTML={{ __html: heading.helperText }} />
                </ContextualContentBox>
            )}
            <Stack gap="16px">
                {visibleSubheadings.length > 0 && (
                    <FormAccordion
                        pages={visibleSubheadings.map((subheading: Heading) => {
                            const childProperties = post ? getVisibleProperties(subheading, post.hiddenElements) : [];
                            const headingScore = headingsScore?.[subheading.id];
                            const value =
                                headingScore && score ?
                                    getScoreVariationValue(headingScore, displayedVariationId)
                                :   undefined;

                            return {
                                title: subheading.title,
                                indicator:
                                    value != null ?
                                        {
                                            value: Number(value.toFixed(score?.precision ?? defaultPrecision)),
                                            unit: scoreVariation?.unit_abbreviation ?? scoreVariation?.unit,
                                        }
                                    :   undefined,
                                content: (
                                    <Stack gap="16px">
                                        {childProperties.map((childProperty: Property) => (
                                            <PropertyContainer
                                                key={childProperty.id}
                                                property={childProperty}
                                                post={post}
                                                formattedScores={formattedScores}
                                                onUpdate={onPropertyUpdate}
                                                onDelete={onPropertyDelete}
                                                canEdit={canEdit}
                                            />
                                        ))}
                                    </Stack>
                                ),
                            };
                        })}
                    />
                )}

                {properties.map((property: Property) => (
                    <PropertyContainer
                        key={property.id}
                        property={property}
                        post={post}
                        formattedScores={formattedScores}
                        onUpdate={onPropertyUpdate}
                        onDelete={onPropertyDelete}
                        canEdit={canEdit}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
