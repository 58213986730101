import { t } from "i18next";
import { Ingredient } from "src/api/client-api/products/useGetOneProduct";
import { IngredientReference } from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";

type TooltipContent = {
    message: string;
    tooltipTitle: string;
    tooltipBody: string;
    linkUrl: string;
};

export const buildIngredientsDataTooltip = (
    ingredient: Ingredient,
    sureScoreId: number,
    unit: string,
    ingredientReferences: IngredientReference[] | undefined,
    ssqUrl: string,
): TooltipContent => {
    const reference = (ingredientReferences ?? []).find(
        (ingredientReference: IngredientReference) => ingredient.ingredientReferenceId === ingredientReference.id,
    );
    const referenceScore = reference?.scores.find((s) => s.score?.id === sureScoreId);

    if (!reference) {
        return {
            message: t("product.ingredient_nonmonolithic_no_reference.message"),
            tooltipTitle: t("product.ingredient_nonmonolithic_no_reference.title"),
            tooltipBody: t("product.ingredient_nonmonolithic_no_reference.body"),
            linkUrl: ssqUrl,
        };
    }

    if (!referenceScore) {
        return {
            message: t("product.ingredient_nonmonolithic_reference_without_score.message"),
            tooltipTitle: t("product.ingredient_nonmonolithic_reference_without_score.title"),
            tooltipBody: t("product.ingredient_nonmonolithic_reference_without_score.body"),
            linkUrl: ssqUrl,
        };
    }

    return {
        message: t("product.ingredient_nonmonolithic_reference_with_score.message"),
        tooltipTitle: t("product.ingredient_nonmonolithic_reference_with_score.title"),
        tooltipBody: t("product.ingredient_nonmonolithic_reference_with_score.body", {
            ingredient: reference.name,
            value: referenceScore.value,
            unit,
        }),
        linkUrl: ssqUrl,
    };
};
