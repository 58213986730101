import { FoodsIcon, IconMapping, LinearProgress } from "@foodpilot/foods";
import { Box, Button, Divider, Stack, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { NavLink } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export type SummaryDataRow = {
    icon: keyof IconMapping;
    title: string;
    percentComplete: number;
    count: number;
    to: string;
};

export type SummaryDataProps = {
    data: SummaryDataRow[];
};

export const SummaryData = ({ data }: SummaryDataProps) => {
    const theme = useTheme();
    if (!data.length) return null;

    return data.map(({ icon, title, percentComplete, count, to }, index) => {
        return (
            <Fragment key={`product-summary-data-row-${index}`}>
                <Stack direction="row" alignItems="center" gap={8} justifyContent="space-between">
                    <Stack flex={1} direction="row" alignItems="center" gap={1.5} minWidth="200px">
                        <Stack
                            sx={{
                                backgroundColor: (theme) => theme.custom.grey[400],
                                borderRadius: theme.spacing(4),
                                padding: theme.spacing(1),
                            }}
                        >
                            <FoodsIcon icon={icon} size={3} />
                        </Stack>
                        <Typography variant="h5">{title}</Typography>
                    </Stack>
                    <Stack
                        flex={2}
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="row"
                        display="flex"
                        gap={4}
                    >
                        <Box>
                            <Typography variant="body" sx={{ textTransform: "lowercase", textWrap: "nowrap" }}>
                                {count} {t("Questionnaires", { count })}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack flex={2} width="100%">
                        <LinearProgress value={percentComplete} />
                    </Stack>
                    <Stack flex={1} direction="row" justifyContent="end">
                        <Button
                            component={NavLink}
                            to={to}
                            variant="ternary"
                            sx={{
                                width: "40px",
                                padding: 0,
                                minWidth: "auto",
                            }}
                        >
                            <FoodsIcon icon="arrowRight" size={2} />
                        </Button>
                    </Stack>
                </Stack>
                {index !== data.length - 1 && <Divider />}
            </Fragment>
        );
    });
};
