import { CMSPageSection } from "src/api/client-api/cms/useGetOneContent";
import { useState } from "react";
import { SectionWrite, useCreateOneSection } from "src/api/client-api/cms/sections/useCreateOneSection";
import { FoodsTextInput, RoundInput, WhiteBox } from "@foodpilot/foods";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { ConfirmationFooter } from "../ConfirmationFooter";
import { EditLayout } from "../EditLayout";

type SectionForm = {
    id: UUID;
    title: string;
    visible: boolean;
};

const getSectionForm = (section: CMSPageSection): SectionForm => {
    const firstBlock: SectionForm = {
        id: section.id,
        visible: section.visible,
        title: section.title,
    };
    return firstBlock;
};

const sectionFormToWrite = (pageId: IRI, form: SectionForm): SectionWrite => {
    const sectionTitle = form.title === "" ? " " : form.title;
    const section: SectionWrite = {
        id: form.id,
        title: sectionTitle,
        page: pageId,
    };
    return section;
};

type SectionFormProps = {
    section: CMSPageSection;
};
export const SectionForm = (props: SectionFormProps) => {
    const { section } = props;
    const theme = useTheme();
    const createSection = useCreateOneSection();

    const indicatorForm = getSectionForm(section);
    const [indicatorFormState, setIndicatorFormState] = useState<SectionForm>(indicatorForm);

    return (
        <EditLayout>
            <Stack width={"350px"} gap={theme.spacing(1)}>
                <Typography variant="big-bold">{t("cms.section.articles.addTitle")}</Typography>
                <FoodsTextInput
                    value={indicatorFormState.title}
                    onChange={(newValue) => {
                        setIndicatorFormState({
                            ...indicatorFormState,
                            title: newValue,
                        });
                    }}
                />
            </Stack>
            <ConfirmationFooter
                saveAction={() => {
                    const newSection = sectionFormToWrite(section.page, indicatorFormState);
                    createSection.mutate({ section: newSection });
                }}
                resetContent={() => {}}
            />
        </EditLayout>
    );
};
