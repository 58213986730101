import { useState } from "react";
import { Box, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import { FormPropertyProps } from "../FormProperty";
import { FoodsCheckbox } from "@foodpilot/foods";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { DefaultValueButton } from "src/components/FormProperty/DefaultValueButton.tsx";
import { useTranslation } from "react-i18next";
import { GenericValueCheckbox } from "src/components/FormProperty/GenericValueCheckbox.tsx";
import { FormTooltip } from "../FormTooltip";
import {
    getPropertyDefaultOptions,
    getPropertyGenericOptions,
    getOptionValues,
    getOptionIds,
    findOptionsByIds,
    findOptionsByValues,
} from "src/utils/dataListOptions";

export const Checkbox = (props: FormPropertyProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { property, post, element, canEdit = true, isSubproperty } = props;

    const options =
        property.dataList?.options.sort(
            (optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position,
        ) ?? [];

    const propertyDefaultOptions = getPropertyDefaultOptions(property, options);
    const propertyGenericOptions = getPropertyGenericOptions(property, options);

    const defaultValueLabel = propertyDefaultOptions.map((option) => option.label).join("; ");

    // Initialize with either the element value or default value
    const initialValue =
        element?.dataListOptionIds?.length ?
            findOptionsByIds(options, element.dataListOptionIds)
        :   findOptionsByValues(options, element?.value ? element.value.split("|") : []);

    const [value, setValue] = useState<DataListOption[] | undefined>(initialValue);
    const [genericValueSelected, setGenericValueSelected] = useState<boolean>(element?.isGenericValue ?? false);

    if (!post) return <CircularProgress />;

    const updatePropertyValue = (value?: DataListOption[], isGenericValue?: boolean) => {
        props.onUpdate({
            postId: post.id,
            propertyId: Number(property.id),
            value: value ? getOptionValues(value) : "_empty_",
            position: Number(props.position ?? 0),
            isGenericValue: isGenericValue,
            dataListOptionIds: value ? getOptionIds(value) : [],
        });
    };

    const tooltipText = property.hint ? <div dangerouslySetInnerHTML={{ __html: property.hint }} /> : undefined;
    return (
        <FormTooltip title={tooltipText} arrow placement="right">
            <Box>
                <Stack direction="column">
                    <Stack direction="row" alignItems="center">
                        <FoodsCheckbox
                            values={options}
                            selectedItems={value}
                            onChange={(selectedValues: DataListOption[]) => {
                                setValue(selectedValues);
                                updatePropertyValue(selectedValues);
                            }}
                            getId={(option: DataListOption) => option.id}
                            getName={(option: DataListOption) => option.label}
                            FormControlLabelProps={{
                                sx: {
                                    gap: "3px",
                                    ".MuiTypography-root": {
                                        ...theme.typography.body,
                                    },
                                },
                            }}
                            CheckboxProps={{
                                disabled: !canEdit || property.readonly || genericValueSelected,
                            }}
                        />
                        {canEdit &&
                            !property.readonly &&
                            propertyDefaultOptions.length > 0 &&
                            !genericValueSelected && (
                                <DefaultValueButton
                                    onClick={() => {
                                        setValue(propertyDefaultOptions);
                                        updatePropertyValue(propertyDefaultOptions);
                                    }}
                                />
                            )}
                    </Stack>
                    {propertyDefaultOptions.length > 0 && !genericValueSelected && (
                        <Typography variant="caption" color={theme.custom.grey[1500]}>
                            {!property.readonly ?
                                t("Valeur par défaut :") + " " + defaultValueLabel
                            :   t("Cette valeur est en lecture seule")}
                        </Typography>
                    )}
                    {canEdit && !property.readonly && propertyGenericOptions.length > 0 && (
                        <Box>
                            <GenericValueCheckbox
                                isSelected={genericValueSelected}
                                onChange={() => {
                                    setGenericValueSelected((prevState) => !prevState);
                                    setValue(!genericValueSelected ? propertyGenericOptions : undefined);
                                    updatePropertyValue(
                                        !genericValueSelected ? propertyGenericOptions : undefined,
                                        !genericValueSelected,
                                    );
                                }}
                            />
                        </Box>
                    )}
                </Stack>
            </Box>
        </FormTooltip>
    );
};
