import { useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { useParams } from "react-router-dom";
import { useGetOneScore } from "src/api/sure-api/score/useGetOneScore.ts";
import { Loading } from "src/components/utils/Loading.tsx";
import { ScoreDetailContextProvider } from "src/context/ScoreDetail/provider.tsx";
import { useIngredientContext } from "src/pages/Ingredient/IngredientDetails/ExistingIngredientContext.tsx";
import { useGetOneIngredient } from "src/api/client-api/ingredients/useGetOneIngredient.ts";

type IngredientScoreContextProviderProps = {
    children: JSX.Element | JSX.Element[];
};
const IngredientScoreContextProvider = (props: IngredientScoreContextProviderProps) => {
    const { children } = props;

    const { selectedCompany, currentCampaign } = useFoodpilotContext();
    const { ingredient, variations, ssq } = useIngredientContext();
    const { scoreId: scoreIdString } = useParams<{ scoreId: string }>();
    const scoreId = Number(scoreIdString);
    const { score: detailedScore } = useGetOneScore(scoreId);

    if (!ingredient?.id) {
        throw new Error("No ingredient ID provided. Cannot fetch ingredient details.");
    }

    const { ingredient: detailedIngredient } = useGetOneIngredient(String(ingredient.id), {
        campaign: currentCampaign?.id,
        company_id: selectedCompany?.id,
        dimensions: [["all"]],
        variations: variations,
    });

    const { scores, prevScores } = detailedIngredient || {};

    if (!detailedIngredient || !detailedScore || !scores) {
        return <Loading />;
    }

    return (
        <ScoreDetailContextProvider scores={scores} prevScores={prevScores} ssq={ssq}>
            {children}
        </ScoreDetailContextProvider>
    );
};

export default IngredientScoreContextProvider;
