import { ScoreDimensionValue } from "src/api/client-api/post/scoreSchema";
import { ScoreChartValues } from "src/components/ScoreChart/ScoreChart";

export interface ChartValuesWithTotal extends ScoreChartValues {
    totalScore: number;
}

export const buildChartValues = (
    dimensionScores: Record<string, ScoreDimensionValue>[] | undefined,
): ChartValuesWithTotal => {
    if (!dimensionScores?.length) return { values: [], labels: [], totalScore: 0 };

    const flattenedDimensions = dimensionScores.flatMap((dimension) => {
        return Object.values(dimension).filter(
            (item) => typeof item === "object" && "score" in item && "label" in item,
        );
    });

    const values = flattenedDimensions.map((d) => d.score ?? 0);
    const totalScore = values.filter((value) => !isNaN(value)).reduce((acc, value) => acc + value, 0);

    return {
        values,
        labels: flattenedDimensions.map((d) => d.label),
        totalScore,
    };
};
