import { FoodsTextInput, HelperBox, NumberField, TextField, useFormBaseContext } from "@foodpilot/foods";
import { Typography, Box, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProductFormBrand, ProductFormProductLine, ProductFormType } from "../../ProductForm.types";
import { useEffect, useRef } from "react";
import { CategoryField } from "./Fields/CategoryField";
import { GammeField } from "./Fields/GammeField";
import { BrandField } from "./Fields/BrandField";
import { ProductRefField } from "./Fields/ProductRefField";

type ProductInformationProps = {
    product: ProductFormType;
    setProduct: (newProduct: ProductFormType) => void;
};
export const ProductInformation = (props: ProductInformationProps) => {
    const { t } = useTranslation();
    const { lockForward, unlockForward } = useFormBaseContext();
    const isWeightModified = useRef(false);
    const theme = useTheme();

    const { product, setProduct } = props;

    const tiles = product?.tags ? product.tags.map((tag) => tag.name) : [];

    const isTitleValid = (title: string | null): boolean => {
        return title !== null && title.trim().length > 0;
    };
    const isClientIdValid = (clientId: string | null): boolean => {
        return clientId !== null && clientId.trim().length > 0;
    };

    const isNetWeightValid = (netWeight: number | null): boolean => {
        return netWeight !== null && netWeight > 0;
    };

    useEffect(() => {
        const isPageError =
            !isTitleValid(product.title) ||
            !isClientIdValid(product.clientId) ||
            !isNetWeightValid(product.netWeight) ||
            !product.productLine;
        if (isPageError) {
            lockForward(t("form.invalidStep"));
            return;
        }
        unlockForward();
    }, [product.productLine, product.title, product.clientId, product.netWeight]);

    const isWeightError = isWeightModified.current && !isNetWeightValid(product.netWeight);
    return (
        <Stack gap={theme.spacing(4)}>
            <Stack flexDirection={"column"} gap={theme.spacing(1)} maxWidth={"70%"}>
                <Typography variant="h5">{`${t("product_name")}* :`}</Typography>
                <FoodsTextInput
                    value={product.title ?? ""}
                    onChange={(newValue) => {
                        setProduct({
                            ...product,
                            title: newValue,
                        });
                    }}
                    error={!isTitleValid(product.title)}
                />
            </Stack>
            <Stack flexDirection={"column"} gap={theme.spacing(1)}>
                <Stack gap={theme.spacing(1)} maxWidth={"70%"}>
                    <Typography variant="h5">{`${t("unique_identifier")}* :`}</Typography>
                    <FoodsTextInput
                        value={product.clientId ?? ""}
                        onChange={(newValue) => {
                            setProduct({
                                ...product,
                                clientId: newValue,
                            });
                        }}
                        error={!isClientIdValid(product.clientId)}
                    />
                </Stack>
                <HelperBox>
                    <Typography variant="body">
                        {t(
                            "L'identifiant unique correspond à la manière dont vous marquer vos produits dans votre entreprise (GTIN, code-barre, etc...).",
                        )}
                    </Typography>
                </HelperBox>
            </Stack>
            <ProductRefField
                productReferenceId={product.productReferenceId}
                setNewProductRef={(newRef: number | null) => {
                    setProduct({
                        ...product,
                        productReferenceId: newRef,
                    });
                }}
            />
            <Stack flexDirection={"column"} gap={theme.spacing(1)}>
                <Stack gap={theme.spacing(1)} maxWidth={"70%"}>
                    <Typography variant="h5">{t("netWeight")}</Typography>
                    <FoodsTextInput
                        value={product.netWeight?.toString() ?? ""}
                        onChange={(newValue) => {
                            const newWeight = newValue === "" ? null : Number(newValue);
                            setProduct({
                                ...product,
                                netWeight: newWeight,
                            });
                        }}
                        unit={"Grammes"}
                        ExtraInputProps={{
                            type: "number",
                        }}
                    />
                </Stack>
                <HelperBox>
                    <Typography variant="body">{t("product.netWeight.info")}</Typography>
                </HelperBox>
            </Stack>
            <BrandField
                brandIRI={product.brand?.["@id"] || null}
                setBrand={(newBrand: ProductFormBrand | null) => {
                    setProduct({
                        ...product,
                        brand: newBrand,
                    });
                }}
            />
            <GammeField
                productLineIRI={product.productLine?.["@id"] ?? null}
                setGamme={(newGamme: ProductFormProductLine | null) => {
                    setProduct({
                        ...product,
                        productLine: newGamme,
                    });
                }}
            />
            <CategoryField tiles={tiles} addAction={setProduct} product={product} />
            <Box>
                <Typography variant="body">{t("form.mandatory-field")}</Typography>
            </Box>
        </Stack>
    );
};
