/* eslint-disable --
    Disable everything. This file is garbage.
*/
import React, { ReactElement, FC } from "react";
import { getSureApiUrl } from "../api/axiosClient/utils";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useOAuth2 } from "../utils/useOAuth2";
import { InexwebHome } from "./Inexweb/InexwebHome";
import { setInexwebInfo } from "src/utils/token";
import { getEnv } from "src/utils/getEnv";

export const Inexweb: FC<any> = (): ReactElement => {
    const navigate = useNavigate();
    const { getAuth } = useOAuth2({
        authorizeUrl: "https://store.inexweb.fr/authorize",
        clientId: getEnv("VITE_INEXWEB_CLIENT_ID"),
        redirectUri: `${document.location.origin}/api/auth/callback`,
        scope: "email",
        responseType: "code",
        exchangeCodeForTokenServerURL: `${getSureApiUrl()}/api/auth/callback`,
        exchangeCodeForTokenMethod: "POST",
        onSuccess: (payload: any) => {
            console.log("Authenticated", payload);
            const json = JSON.parse(payload);
            setInexwebInfo(
                json.access_token,
                json.company,
                json.company_corporate_name,
                json.company_identification_number,
            );
            navigate("/inexweb/form/0");
        },
        onError: (error_: any) => console.log("Error", error_),
    });

    return (
        <Container component="main" maxWidth={false} sx={{ m: "0", p: "0 !important", flex: "1 1 auto" }}>
            <InexwebHome onStart={getAuth} />
        </Container>
    );
};
