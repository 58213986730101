import { useState, Dispatch, SetStateAction } from "react";
import { Box, BoxProps, Stack, Typography, TypographyProps, useTheme } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { AnchoredPopover, OptionsPopover, OptionsPopoverOption } from "@foodpilot/foods";
import { Score as SureScore } from "src/types";
import { doesScoreExist, DeprecatedEmptyScore, Score, ScoreVariation } from "src/api/client-api/post/scoreSchema";
import { ScoreVariation as ScoreVariationScoreServiceType } from "src/context/EntityBasedScore/ScoreService";

const isPopoverActive = (value?: Score | null | DeprecatedEmptyScore): boolean => {
    if (value === undefined) return false;

    if (doesScoreExist(value)) {
        const variations = value.variations ?? [];
        if (variations.length > 0) {
            return true;
        }
    }

    return false;
};

export type UnitSwitcherProps = {
    score: SureScore;
    value?: Score | null | DeprecatedEmptyScore;
    displayedVariations: Record<number, number>;
    setDisplayedVariations: (id: Record<number, number>) => void;
    boxProps?: BoxProps;
    typographyProps?: TypographyProps;
    allScoreWithVariations?: ScoreVariationScoreServiceType[];
    selectedVariations?: Record<number, number>;
};

export const UnitSwitcher = (props: UnitSwitcherProps) => {
    const {
        score,
        selectedVariations,
        allScoreWithVariations,
        value,
        displayedVariations,
        setDisplayedVariations,
        typographyProps = {},
        boxProps,
    } = props;
    const theme = useTheme();
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const active = isPopoverActive(value);

    const getSelectedVariationId = () => {
        if (selectedVariations && selectedVariations[score.id]) {
            return selectedVariations[score.id] || score.id;
        }

        const scoreAsVariation = value?.variations?.find((v) => v.id === score.id)?.id;
        if (scoreAsVariation) {
            return scoreAsVariation;
        }

        if (allScoreWithVariations) {
            return score.id;
        }

        return undefined;
    };

    const selectedVariationId = getSelectedVariationId();

    const variations = value?.variations ?? [];
    const displayedVariation =
        active ?
            variations.find((variation: ScoreVariation) => {
                return variation.id === selectedVariationId;
            })
        :   undefined;
    const displayedVariationLabel = displayedVariation ? displayedVariation.label : value?.label;

    return (
        <Box {...boxProps}>
            <Stack
                direction="row"
                alignItems="center"
                gap="4px"
                onClick={(e) => {
                    if (!active) return;

                    setAnchor(e.currentTarget);
                }}
                sx={{
                    cursor: active ? "pointer" : "default",
                    opacity: active ? 1 : 0.36,
                }}
            >
                <Typography variant="body" {...typographyProps}>
                    {displayedVariationLabel}
                </Typography>
                <KeyboardArrowDown sx={{ width: "16px", height: "16px", color: theme.custom.grey[2800] }} />
            </Stack>

            {active && (
                <UnitSwitcherPopover
                    score={score}
                    // Kilian: i dont have it in me to change it rightnow
                    value={value as Score | DeprecatedEmptyScore}
                    displayedVariations={displayedVariations}
                    setDisplayedVariations={setDisplayedVariations}
                    anchor={anchor}
                    setAnchor={setAnchor}
                    selectedVariationId={selectedVariationId || displayedVariations[score.id]}
                    allScoreWithVariations={allScoreWithVariations}
                />
            )}
        </Box>
    );
};

type UnitSwitcherPopoverProps = {
    score: SureScore;
    value: Score | null | DeprecatedEmptyScore;
    displayedVariations: Record<number, number>;
    setDisplayedVariations: (variations: Record<number, number>) => void;
    anchor: null | HTMLElement;
    setAnchor: Dispatch<SetStateAction<null | HTMLElement>>;
    selectedVariationId?: number;
    allScoreWithVariations?: ScoreVariationScoreServiceType[];
};

const UnitSwitcherPopover = (props: UnitSwitcherPopoverProps) => {
    const {
        score,
        value,
        anchor,
        setAnchor,
        displayedVariations,
        setDisplayedVariations,
        selectedVariationId,
        allScoreWithVariations,
    } = props;
    const isOpen = Boolean(anchor);

    const mainScoreOption = {
        id: allScoreWithVariations && allScoreWithVariations.length ? score.id : 0,
        label: score.label ?? score.title,
    };

    const handleChange = (option: OptionsPopoverOption) => {
        const variationId = Number(option.id);
        const variations = { ...displayedVariations, [score.id]: variationId };

        if (!variationId) {
            delete variations[score.id];
        }

        if (allScoreWithVariations && allScoreWithVariations.length) {
            const selectedVariation = allScoreWithVariations.find((sc) => sc.id === variationId);
            if (selectedVariation && selectedVariation.onClick) {
                selectedVariation.onClick();
            }
        }

        setDisplayedVariations(variations);
        setAnchor(null);
    };

    return (
        <AnchoredPopover
            variant="headless"
            anchor={anchor}
            open={isOpen}
            onClose={() => setAnchor(null)}
            anchorPosition="center"
        >
            <OptionsPopover
                content={[
                    {
                        header: score.title,
                        options: [mainScoreOption, ...(value?.variations ?? [])],
                    },
                ]}
                onChange={handleChange}
                defaultIndex={selectedVariationId}
                minWidth="287px"
            />
        </AnchoredPopover>
    );
};
