import { Box, Stack, styled, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Ingredient } from "src/api/client-api/products/useGetOneProduct";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { useProductContext } from "../ProductContext";
import { URLHandler } from "src/services/URLHandler";
import { FoodsIcon, FoodsTooltip, IconMapping, LinearProgress } from "@foodpilot/foods";
import { buildIngredientsDataTooltip } from "./buildIngredientsDataTooltip";
import { NavLink } from "react-router-dom";
import { IngredientCompletionService } from "./IngredientCompletionService";
import { IngredientPost } from "src/api/client-api/post/useGetSomeIngredientPosts";

type IngredientDataRowProps = {
    ingredient: Ingredient;
    scoreId: number;
    unit: string;
    posts: IngredientPost[];
};

const StyledNavLink = styled(NavLink)(({ theme }) => ({
    color: theme.palette.text.primary,
    textDecoration: "none",
    padding: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
}));

export const IngredientsDataRow = (props: IngredientDataRowProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { ingredient, scoreId: sureScoreId, unit, posts } = props;
    const { selectedCompany } = useFoodpilotContext();
    const { ingredientReferences } = useProductContext();

    const { totalIngredientCompletion = 0, supplierQuestionnaireFilled = 0 } =
        IngredientCompletionService.calculateTotalCompletionRates([{ ingredient }], posts) ?? {};
    const supplierCount = ingredient?.ingredientSuppliers?.length ?? 0;

    const ssqUrl = URLHandler.ingredient.ssq(
        selectedCompany?.id ?? 0,
        ingredient.id,
        ingredient.ingredientSuppliers?.[0]?.supplier.id ?? undefined,
    );

    const progressRatio = supplierCount ? supplierQuestionnaireFilled / supplierCount : 0;
    const icon: keyof IconMapping = progressRatio === 1 ? "circleCheck" : "inProgress";

    const tooltipContent =
        posts?.length === 0 ?
            buildIngredientsDataTooltip(ingredient, sureScoreId, unit, ingredientReferences, ssqUrl)
        :   { message: "", tooltipTitle: "", tooltipBody: "", linkUrl: ssqUrl };

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
            <Stack flex={3}>
                <Typography variant="h4">{ingredient.name}</Typography>
                {!!(posts?.length ?? 0) && (
                    <Stack direction="row" alignItems="center" gap={1} color={theme.palette.text.secondary}>
                        <FoodsIcon icon={icon} size={2} />
                        <Typography variant="small-bold" color={theme.palette.text.secondary}>
                            {supplierQuestionnaireFilled} / {supplierCount}{" "}
                            {t("ingredient.activityForm.suppliers", { count: supplierCount })}
                        </Typography>
                    </Stack>
                )}
            </Stack>

            <Stack flex={2} justifyContent="flex-start">
                {posts?.length === 0 ?
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="body">{tooltipContent.message}</Typography>
                        <FoodsTooltip
                            sx={{
                                "& .MuiTooltip-tooltip": {
                                    maxHeight: "unset",
                                    height: "unset",
                                },
                            }}
                            arrow
                            placement="top"
                            title={
                                <Box padding="8px" textAlign="left">
                                    <Typography variant="h4" sx={{ marginBottom: theme.spacing(2) }}>
                                        {tooltipContent.tooltipTitle}
                                    </Typography>
                                    <Typography variant="body-bold" color={theme.palette.text.secondary}>
                                        {tooltipContent.tooltipBody}
                                    </Typography>
                                </Box>
                            }
                        >
                            <Box display="flex">
                                <FoodsIcon icon="information" size={2} />
                            </Box>
                        </FoodsTooltip>
                    </Stack>
                :   <LinearProgress value={totalIngredientCompletion} />}
            </Stack>

            <Stack flex={1} alignItems="flex-end">
                <StyledNavLink to={tooltipContent.linkUrl} target="_blank">
                    <FoodsIcon icon="openNewTab" size={3} />
                </StyledNavLink>
            </Stack>
        </Stack>
    );
};
