import { FoodsIllustrations, FoodsSelect, FoodsTextInput, FormBox, IllustrationMapping } from "@foodpilot/foods";
import { CsrPillarForm } from "../PillarForm";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";

type PillarInfoProps = {
    form: CsrPillarForm;
    setForm: (form: CsrPillarForm) => void;
};
export const PillarInfo = (props: PillarInfoProps) => {
    const { form, setForm } = props;

    const theme = useTheme();
    const { t } = useTranslation();

    const strategyIllustrations: (keyof IllustrationMapping)[] = [
        "environement",
        "communities",
        "filliere",
        "relationships",
    ];
    return (
        <FormBox>
            <Stack gap={theme.spacing(2)}>
                <Stack>
                    <Typography variant="big-medium">{t("settings.csr.pillars.fields.name.label")}</Typography>
                    <FoodsTextInput
                        value={form.name}
                        onChange={(newValue) => {
                            setForm({
                                ...form,
                                name: newValue,
                            });
                        }}
                        ExtraInputProps={{
                            placeholder: t("settings.csr.pillars.fields.name.placeholder"),
                        }}
                    />
                </Stack>

                <Stack>
                    <Typography variant="big-medium">{t("settings.csr.pillars.fields.themes.label")}</Typography>
                    <FoodsSelect
                        selectedOption={null}
                        options={[{ id: 0, name: "" }]}
                        onChange={(selectedValue) => {}}
                        disabled
                        getId={(item) => item.id}
                        getName={(item) => item.name}
                        textOptions={{
                            placeholder: t("settings.csr.pillars.fields.themes.placeholder"),
                            noResult: "",
                            searchPlaceholder: "",
                            emptyOptionsText: t("select.generic.no-options"),
                        }}
                    />
                </Stack>

                <Stack>
                    <Typography variant="big-medium">{t("settings.csr.pillars.fields.icon")}</Typography>
                    <Stack flexDirection={"row"} gap={theme.spacing(2)}>
                        {strategyIllustrations.map((iconName, index) => {
                            const isSelected = iconName === form.icon;
                            return (
                                <Fragment key={index}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            maxWidth: "50px",
                                            border: isSelected ? `2px solid black` : "none",
                                        }}
                                        onClick={() => {
                                            setForm({
                                                ...form,
                                                icon: iconName,
                                            });
                                        }}
                                    >
                                        <FoodsIllustrations
                                            illustration={iconName}
                                            backgroundShape={"rect"}
                                            height={50}
                                        />
                                    </Box>
                                </Fragment>
                            );
                        })}
                    </Stack>
                </Stack>
            </Stack>
        </FormBox>
    );
};
