import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ComparisonBlock } from "@foodpilot/foods";

export type ScoreComparisonProps = {
    referenceScoreValue: number;
    scoreValueToCompare: number;
    unit?: string;
    precision?: number;
    reportName: string;
};

export const ScoreComparison = (props: ScoreComparisonProps): JSX.Element => {
    const { t } = useTranslation();
    const { scoreValueToCompare, referenceScoreValue, unit, precision, reportName } = props;

    return (
        <Box
            sx={{
                display: "flex",
                gap: "0.6rem",
                alignItems: "center",
            }}
        >
            <ComparisonBlock
                currentValue={scoreValueToCompare}
                layout={{
                    direction: "horizontal",
                    size: "M",
                }}
                comparedTo={{
                    value: Number(referenceScoreValue.toFixed(precision)),
                    unit: unit,
                    label: reportName,
                }}
                improvement="increase"
                comparisonText={t("score_reference.in_relation")}
            />
        </Box>
    );
};
