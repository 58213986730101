import { useNavigate, useParams } from "react-router-dom";
import { DistributionImpactsDetails } from "src/components/ScoreDetail/SecondaryTabs/DistributionImpactsDetails";
import { DistributionDetails } from "src/components/ScoreDetail/SecondaryTabs/DistributionDetails";
import { useIsVariationRefetching } from "src/utils/variationRefetching.ts";
import { Box } from "@mui/material";
import { URLHandler } from "src/services/URLHandler.ts";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { ResponsesModal } from "src/components/ScoreDetail/Responses/ResponsesModal.tsx";
import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext";
import { useChartActions } from "src/components/ScoreDetail/useChartActions.ts";
import { ScoreDetailGlossary } from "src/components/ScoreDetail/ScoreDetailGlossary";
import { useIngredientContext } from "src/pages/Ingredient/IngredientDetails/ExistingIngredientContext";
import { useGetIngredientPost } from "src/api/client-api/post/useGetIngredientPost.ts";
import { useGetIngredientReferenceScores } from "src/api/client-api/ingredientReferenceScores/useGetIngredientReferenceScores.ts";
import { getNumberOfSuppliers } from "src/pages/Ingredient/IngredientDetails/IngredientScores.tsx";
import { useGetIngredientPosts } from "src/api/client-api/post/useGetIngredientPosts.ts";

const dimensionOrder = ["headings", "lca", "pef_chapters"];

export const Distribution = () => {
    const navigate = useNavigate();
    const { ingredient, isRefetching, variations, activeSupplierId } = useIngredientContext();
    const { selectedCompany, currentCampaign } = useFoodpilotContext();
    const { list, scorePrecision, selectedDimension, additionalScore, selectedDimensionId } = useScoreDetailContext();
    const { posts } = useGetIngredientPosts(ingredient?.id, currentCampaign?.id);
    const { ingredientReferenceScores = [] } = useGetIngredientReferenceScores(ingredient?.id);

    const { scoreId: scoreIdString } = useParams<{ scoreId: string }>();
    const scoreId = Number(scoreIdString);
    const isVariationRefetching = useIsVariationRefetching(isRefetching, variations);

    const { actions, selectedScore, dialogOpen, dialogTitle, propertyId, setDialogOpen } = useChartActions(
        additionalScore !== null,
    );

    const mappedReferenceScores = Object.fromEntries(
        ingredientReferenceScores.map((score) => [score?.score?.id, score]),
    );
    const referenceScore = mappedReferenceScores[Number(scoreId)];
    const suppliers = ingredient !== undefined ? ingredient.ingredientSuppliers : [];

    const values = Object.values(list || {});

    return (
        <Box>
            <ScoreDetailGlossary
                dimensionOrder={dimensionOrder}
                baseUrl={
                    selectedCompany ? URLHandler.ingredient.score(selectedCompany.id, ingredient!.id, scoreId) : ""
                }
                additionalDimensions="components"
            />
            {selectedDimension === "pef_chapters" ?
                <DistributionImpactsDetails
                    referenceScoreValue={
                        referenceScore?.value !== undefined ?
                            Number(referenceScore.value.toFixed(scorePrecision))
                        :   undefined
                    }
                    isVariationRefetching={isVariationRefetching}
                    prevCampaignName={ingredient?.prevCampaignName}
                    labels={values.map((value) => value.label || "-- no label --")}
                    actions={actions}
                    values={values.map((value) =>
                        value.score !== null ? Number((value.score ?? 0).toFixed(scorePrecision)) : 0,
                    )}
                />
            :   <DistributionDetails
                    referenceScoreValue={
                        referenceScore?.value !== undefined ?
                            Number(referenceScore.value.toFixed(scorePrecision))
                        :   undefined
                    }
                    isVariationRefetching={isVariationRefetching}
                    prevCampaignName={ingredient?.prevCampaignName}
                    labels={values.map((value) => value.label || "-- no label --")}
                    chartValues={values.map((value) => Number((value.score ?? 0).toFixed(scorePrecision)))}
                    actions={actions}
                />
            }
            {dialogOpen && propertyId && (
                <ResponsesModal
                    posts={posts}
                    title={dialogTitle}
                    open={dialogOpen}
                    propertyId={propertyId}
                    score={selectedScore}
                    setOpen={setDialogOpen}
                    suppliers={suppliers}
                    onModifyAnswersClick={() => {
                        if (!selectedCompany || !ingredient || !selectedDimensionId) {
                            return;
                        }

                        const url = URLHandler.ingredient.ssqHeading(
                            selectedCompany.id,
                            ingredient.id,
                            activeSupplierId || 0,
                            Number(selectedDimensionId),
                        );
                        navigate(url);
                    }}
                />
            )}
        </Box>
    );
};
