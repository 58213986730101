import { Fragment, ReactNode, useState, useEffect } from "react";
import { ArrowForward, Check } from "@mui/icons-material";
import { Box, Button, Stack, Typography, CircularProgress, useTheme } from "@mui/material";
import { SectionGrid } from "src/components/SectionGrid/SectionGrid";
import { Post } from "src/api/client-api/post/postSchema";
import { Ssq, Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { SectionBox } from "@foodpilot/foods";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQuestionnairesColumns } from "./useQuestionnairesColumns";
import { getVisibleHeadings } from "src/utils/ssq";
import { QuestionnairesListHeader } from "./QuestionnairesListHeader";
import { formatDateTime } from "src/utils/formatting.ts";
import { EmptyRowsFallback } from "src/components/Grid/EmptyRowsFallback";
import { LockPostModal } from "src/components/QuestionnairesList/LockPostModal.tsx";
import { MRT_TableOptions } from "material-react-table";

export type HeadingsSectionData = {
    heading: Heading;
    completion?: number;
};

type QuestionnairesListProps = {
    title?: string;
    ssq?: Ssq;
    post?: Post;
    isSsqLoading: boolean;
    isPostLoading: boolean;
    completeQuestionnaireUrl: (heading: Heading) => string;
    scoresSection: ReactNode;
    canEdit?: boolean;
    displayDateLastModified?: boolean;
    enableTableHead?: boolean;
    muiTableProps?: Omit<MRT_TableOptions<HeadingsSectionData>["muiTableProps"], "columns" | "data">;
    tableMode?: "table" | "list" | "extended";
};

export const QuestionnairesList = (props: QuestionnairesListProps) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);

    const questionnairesColumns = useQuestionnairesColumns();

    const {
        title,
        ssq,
        post,
        isSsqLoading,
        isPostLoading,
        completeQuestionnaireUrl,
        displayDateLastModified = true,
        scoresSection,
        canEdit = true,
        tableMode = "table",
    } = props;

    const [isLocked, setLocked] = useState<boolean>(post ? post.isLocked : false);

    useEffect(() => {
        if (post !== undefined) {
            setLocked(post.isLocked);
        }
    }, [post]);

    const headings: Heading[] = ssq ? getVisibleHeadings(ssq, post?.hiddenElements) : [];

    const updatedAtDates = post?.elements;
    let updatedAtDate: string = "-";

    if (updatedAtDates && updatedAtDates.length > 0) {
        const mostRecentDate: Date = new Date(
            Math.max(...updatedAtDates.map((element) => Date.parse(element.updatedAt ?? ""))),
        );

        updatedAtDate = formatDateTime(mostRecentDate, i18n.resolvedLanguage);
    }

    const headingsData: HeadingsSectionData[] = headings.map((heading: Heading) => {
        return {
            heading: heading,
            completion: !isPostLoading ? post?.completions.headings[heading.id]?.result ?? 0 : undefined,
        };
    });

    const questionnaireCompleteText =
        canEdit && !isLocked ? t("questionnaire.complete-questionnaire") : t("questionnaire.consult-questionnaire");

    return (
        <SectionBox
            header={
                <Stack direction="row" alignItems="center" gap="12px">
                    <Stack flex={1}>
                        <QuestionnairesListHeader
                            title={title}
                            isSsqLoading={isSsqLoading}
                            isPostLoading={isPostLoading}
                            totalCompletion={post?.completions.ssq.result}
                        />
                    </Stack>

                    {displayDateLastModified && (
                        <Stack flex={1}>
                            <Typography variant="h4">{t("Date dernière modification :")}</Typography>
                            <Typography variant="caption">{updatedAtDate}</Typography>
                        </Stack>
                    )}

                    <Stack direction="row" gap="12px">
                        {canEdit && post !== undefined && (
                            <Fragment>
                                <LockPostModal
                                    isOpen={isOpen}
                                    setOpen={setOpen}
                                    setLocked={setLocked}
                                    isLocked={isLocked}
                                    post={post}
                                />
                                {isLocked && (
                                    <Box alignItems="center" display="flex">
                                        <Check
                                            sx={{
                                                color: "green",
                                                width: "1rem",
                                                height: "1rem",
                                                marginTop: "-1px",
                                                marginRight: "0.3rem",
                                            }}
                                        />
                                        <Typography color="green" variant="caption">
                                            {" "}
                                            {t("questionnaire.locked_information")}
                                        </Typography>
                                    </Box>
                                )}
                                <Button
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                    variant={isLocked ? "ternary" : "validate"}
                                    color={isLocked ? "inherit" : "success"}
                                    startIcon={isLocked ? "" : <Check />}
                                >
                                    {isLocked ?
                                        t("questionnaire.button.unlock-questionnaire")
                                    :   t("questionnaire.button.lock-questionnaire")}
                                </Button>
                            </Fragment>
                        )}
                        <Button
                            onClick={() => {
                                if (!headings.length) return;

                                navigate(completeQuestionnaireUrl(headings[0]));
                            }}
                            variant="primary"
                            endIcon={<ArrowForward />}
                            disabled={!headings.length}
                        >
                            {questionnaireCompleteText}
                        </Button>
                    </Stack>
                </Stack>
            }
        >
            <Box display="flex" gap="28px">
                <Stack alignItems="center" flex={5}>
                    {isSsqLoading ?
                        <CircularProgress />
                    :   <SectionGrid
                            mode={tableMode}
                            columns={questionnairesColumns}
                            data={headingsData}
                            enablePagination={false}
                            renderRowActions={(value) => {
                                const isCompleted = value.row.original.completion >= 100;
                                const goToQuestionnaireText =
                                    !canEdit || isLocked ? t("questionnaire.consult")
                                    : isCompleted ? t("questionnaire.modify")
                                    : t("questionnaire.complete");

                                return (
                                    <Stack display="inline-flex" direction="row" alignItems="center" gap="12px">
                                        <Button
                                            onClick={() => {
                                                navigate(completeQuestionnaireUrl(value.row.original.heading));
                                            }}
                                            variant="ternary"
                                            endIcon={<ArrowForwardIcon fill={theme.custom.grey[2800]} />}
                                            sx={{ minWidth: "132px" }}
                                        >
                                            {goToQuestionnaireText}
                                        </Button>
                                    </Stack>
                                );
                            }}
                            renderEmptyRowsFallback={() => (
                                <EmptyRowsFallback message={t("Il n'y a aucun thématique à afficher")} />
                            )}
                            {...props}
                        />
                    }
                </Stack>

                <Stack
                    sx={{
                        height: "fit-content",
                        position: "sticky",
                        top: "28px",
                    }}
                    flex={2}
                >
                    {!isSsqLoading && !isPostLoading ? scoresSection : <CircularProgress sx={{ margin: "0 auto" }} />}
                </Stack>
            </Box>
        </SectionBox>
    );
};
