import { Box, Stack } from "@mui/material";
import { useProductContext } from "../ProductContext";
import { useEffect } from "react";
import { IngredientsData } from "./IngredientsData";
import { ScoreChart } from "src/components/ScoreChart/ScoreChart";
import { buildChartValues } from "src/utils/buildScoreChartValues";
import { IngredientsHeader } from "./IngredientsHeader";
import { SectionBox } from "@foodpilot/foods";

const DIMENSION = "ingredients";

export const Ingredients = () => {
    const { product, productScores, allScoreWithVariations, scoreState, switchToDimension, isLoading, isRefetching } =
        useProductContext();

    useEffect(() => {
        switchToDimension(DIMENSION);
    }, []);

    const selectedItemId = scoreState.lastSelectedVariation?.scoreId ?? allScoreWithVariations[0].id;

    const score = product?.scores[selectedItemId];
    const dimensionScores = score?.dimensions.filter((d) => d.label === DIMENSION).map((d) => d.values);

    const precision = productScores.find((ps) => ps.id === selectedItemId)?.precision;
    const chartValues = buildChartValues(dimensionScores);
    const unit = productScores.find((ps) => ps.id === selectedItemId)?.unit?.abbreviation ?? "";

    return (
        <SectionBox header={<IngredientsHeader />} borderRadius={4} sx={{ backgroundColor: "white" }}>
            <Stack direction={"row"} gap={3.5} sx={{ backgroundColor: "white" }}>
                <Stack flex={5} gap={3}>
                    <IngredientsData product={product} scoreId={selectedItemId} unit={unit} />
                </Stack>

                <Box flex={2}>
                    <ScoreChart
                        precision={precision}
                        allScoreWithVariations={allScoreWithVariations}
                        totalScore={chartValues.totalScore}
                        data={chartValues}
                        unit={unit}
                        isLoading={isLoading}
                        isRefetching={isRefetching}
                    />
                </Box>
            </Stack>
        </SectionBox>
    );
};
