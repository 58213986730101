import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { useSnackbar } from "src/utils/useSnackbar";
import { useTranslation } from "react-i18next";

type LockUnlockOptions = {
    postId: number;
    isLocked: boolean;
};

export const useLockUnlockPost = () => {
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: async (options: LockUnlockOptions) => {
            const { postId, isLocked } = options;

            const url = `/api/ssq_posts/${postId}`;
            const payload = {
                isLocked: !isLocked,
            };

            return await loggedInApiCall.put(url, payload, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        onError: () => {
            snackbar.forceClose();
            snackbar.setSnackbarProps({
                type: "error",
                message: t("ssq.form.error"),
            });
        },
    });
};
