import { useTranslation } from "react-i18next";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ComparisonBlock, dataVisualizationColors, DoughnutChart, FoodsIcon } from "@foodpilot/foods";
import { formatNumber } from "src/utils/formatting.ts";
import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext.tsx";

type ColorKeys = keyof typeof dataVisualizationColors;

type DistributionScoreProps = {
    referenceScoreValue?: number;
    isVariationRefetching: boolean;
    prevCampaignName?: string;
    labels: string[];
    actions?: ((() => void) | undefined)[];
    chartValues?: number[];
    variations?: Record<number, number>;
};

export const DistributionDetails = (props: DistributionScoreProps) => {
    const {
        prevCampaignName,
        referenceScoreValue,
        isVariationRefetching,
        labels,
        actions,
        chartValues,
        variations = {},
    } = props;
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const {
        scoreId,
        depthLevel = 0,
        selectedChartItemIndex,
        getMainScore,
        getScoreValue,
        getPrevScoreValue,
        scorePrecision,
        dimension,
    } = useScoreDetailContext();

    const getPalette = () => {
        const colors = theme.custom.dataviz.colorsWithExtra;

        if (depthLevel === 0) {
            return Object.values(colors).map((color) => color["main"]);
        } else if (depthLevel === 1 && selectedChartItemIndex !== undefined) {
            const propIdx = (selectedChartItemIndex + 1).toString() as unknown as ColorKeys;
            const selectedPalette = (colors[propIdx] || colors["1"]).lv1;
            return selectedPalette ? [selectedPalette.min, selectedPalette.middle, selectedPalette.max] : [];
        } else if (depthLevel === 2 && selectedChartItemIndex !== undefined) {
            const propIdx = (selectedChartItemIndex + 1).toString() as unknown as ColorKeys;
            const selectedPalette = (colors[propIdx] || colors["2"]).lv2;
            return selectedPalette ? [selectedPalette.min, selectedPalette.middle, selectedPalette.max] : [];
        }
    };

    const mainScore = getMainScore();
    const unit = mainScore ? mainScore.unit : "";
    const scoreValue = getScoreValue();
    const prevScoreValue = getPrevScoreValue();

    const isPrevScore =
        prevScoreValue !== undefined &&
        prevScoreValue !== null &&
        !isVariationRefetching &&
        prevCampaignName !== undefined;

    const displayedVariationId = variations[scoreId];
    const isReferenceScore =
        referenceScoreValue !== null &&
        referenceScoreValue !== undefined &&
        !isVariationRefetching &&
        !displayedVariationId;

    return (
        <Stack direction="row" gap="24px">
            {prevCampaignName && (
                <Stack
                    sx={{
                        width: "170px",
                        minWidth: "170px",
                        "& .comparison-block-wrapper": {
                            width: "100%",
                        },
                    }}
                    direction="column"
                    gap="24px"
                >
                    <Box>
                        {isPrevScore && (
                            <ComparisonBlock
                                currentValue={scoreValue}
                                comparedTo={{
                                    value: Number(prevScoreValue.toFixed(scorePrecision)),
                                    unit: unit,
                                    label: prevCampaignName,
                                }}
                                improvement="increase"
                                layout={{
                                    direction: "vertical",
                                    size: "L",
                                }}
                            />
                        )}
                    </Box>
                    {isReferenceScore && (
                        <ComparisonBlock
                            currentValue={scoreValue}
                            comparedTo={{
                                value: Number(referenceScoreValue.toFixed(scorePrecision)),
                                unit: unit,
                                label: t("score_reference.the_reference"),
                            }}
                            improvement="increase"
                            layout={{
                                direction: "vertical",
                                size: "L",
                            }}
                        />
                    )}
                </Stack>
            )}

            <Box
                display="flex"
                sx={{
                    width: "100%",
                    border: `1px solid ${theme.palette.grey[500]}`,
                    borderRadius: "12px",
                    padding: "16px",
                    justifyContent: "center",
                }}
            >
                <DoughnutChart
                    createPalette={depthLevel > 0}
                    colors={getPalette()}
                    values={chartValues}
                    valuesFormatterFn={(value) => formatNumber(value, i18n.resolvedLanguage)}
                    labels={labels}
                    legend={{
                        position: "right",
                        actions: actions,
                        seeMore:
                            dimension?.label === "properties" ?
                                <Box
                                    color={theme.custom.grey[2800]}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <FoodsIcon size={1} icon="questionnaireAnswer" />
                                </Box>
                            :   <>
                                    <Box sx={{ mr: "0.5rem" }}>{t("See details")}</Box>
                                    <FoodsIcon size={1} icon="arrowRight" />
                                </>,
                    }}
                >
                    <Box display="flex" flexDirection="column">
                        <Box key="content-1" fontWeight={800} fontSize={"3rem"}>
                            {formatNumber(scoreValue, i18n.resolvedLanguage)}
                        </Box>
                        {unit && (
                            <Typography variant="body-medium" color={theme.custom.grey[1400]}>
                                {unit}
                            </Typography>
                        )}
                    </Box>
                </DoughnutChart>
            </Box>
        </Stack>
    );
};
